import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stringify } from "uuid";
import { RootState } from "../../app/store";
import { UplinkData } from "../../services/dataAPI";

export interface DataState {
  data: DataType;
}

export type DataType = {
  [key: string]: UplinkData[];
};

const initialState: DataState = {
  data: {},
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateDeviceData: (state: any, action: PayloadAction<UplinkData[]>) => {
      //console.log("updateDeviceData payload: ", action.payload);
      //console.log("updateDeviceData action ", action);
      //console.log("updateDeviceData state ", state);
      state.data /*[action.payload[0].Eui]*/ = {
        ...state.data,
        [action.payload[0].eui]: { ...action.payload },
      };
    },
    clearDataData: (state: any) => {
      state.data = [];
    },
  },
});

export const selectData = (state: RootState) => state.data;

export const { updateDeviceData, clearDataData } = dataSlice.actions;

export default dataSlice.reducer;

//I heada back to store.tsx that is found in the folder app
