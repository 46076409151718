import React, { ForwardedRef, ReactNode, useCallback, useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./dashboard.css";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { IoMdClose } from "react-icons/io";
import {
  LineChart,
  Gauge,
  BarChart,
  ScatterChart,
  Calendar,
  ImageMap,
  Map,
  Value,
  Text,
  Table,
  EditBarChart,
  EditScatterChart,
  EditGauge,
  EditCalendar,
  EditTable,
  EditText,
  EditMap,
  EditImageMap,
  EditLineChart,
  ChartType,
  EditValue,
} from "../../components/dashboard/charts";
import ReactTooltip from "react-tooltip";
import ChartSelect from "../../components/dashboard/ChartSelect";
import DashboardBlockEdit from "../../components/dashboard/DashboardBlockEdit";
import EditChartSettings from "../../components/dashboard/EditChartSettings";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function ViewDashboard() {
  const originalLayouts = getFromLS("layouts") || [];
  const originalCharts = getFromLS("charts") || [];
  const [layout, setLayout] = useState(JSON.parse(JSON.stringify(originalLayouts)));
  const [items, setItems] = useState<ChartType[]>(
    JSON.parse(JSON.stringify(originalCharts))
  );
  const [colls, setColls] = useState(5);
  const [breakpoint, setBreakpoint] = useState("lg");
  const [editItem, setEditItem] = useState(false);
  const [showChartEdit, setShowChartEdit] = useState<boolean>(false);
  const [chartEditInfo, setChartEditInfo] = useState<ReactNode>();

  const createElement = (el: ChartType) => {
    const i = el.i;
    const data = _.find(items, { i: i });

    return (
      <div key={i} className="bg-white p-2">
        <DashboardBlockEdit
          onSelect={() => {
            data !== undefined ? handleEditBlock(data.type, i) : console.log("no data");
          }}
          showEdit={editItem}
          setShowChartEdit={setShowChartEdit}
        />
        {data ? Chart(data) : null}
      </div>
    );
  };

  const onAddItem = (type: string) => {
    /*eslint no-console: 0*/
    const id = uuidv4();
    //console.log("adding", id, "type", type);

    setItems(
      _.concat(items, {
        i: id,
        type: type,
        title: { en: "" },
        chartData: [],
      })
    );

    setLayout(
      _.concat(layout, {
        i: id,
        x: 0,
        y: 0,
        w: 5,
        h: 5,
      })
    );
  };

  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = (breakpoint: string, cols: number) => {
    setBreakpoint(breakpoint);
    setColls(cols);
  };

  const onLayoutChange = (layout: any) => {
    //onLayoutChange(layout);
    if (editItem) {
      setLayout(layout);
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  };

  const onRemoveItem = (i: any) => {
    //console.log("removing", i);
    setItems(_.reject(items, { i: i }));
    setLayout(_.reject(layout, { i: i }));
  };

  const updateChart = (data: ChartType) => {
    if (data.chartData !== undefined) {
      console.log(
        "Saving chart settings",
        data.title,
        data.timeFormat,
        data.chartData,
        items,
        data.i
      );
      setItems(
        _.map(items, (item: ChartType) => {
          if (item.i === data.i) {
            item.i = data.i;
            item.type = data.type;
            item.title.en = data.title.en;
            item.chartData = data.chartData;
          }
          return item;
        })
      );
    }
  };

  const handleEditBlock = (type: string, i: string) => {
    const Editdata = _.find(items, { i: i });
    setEditItem(true);
    setChartEditInfo(ChartEdit(Editdata, i));
    return console.log("edit", i, type, Editdata);
  };

  const handleResize = useCallback(
    (
      l: any,
      oldLayoutItem: { h: number; w: number },
      layoutItem: { h: number; w: number },
      placeholder: { h: number; w: number }
    ) => {
      const heightDiff = layoutItem.h - oldLayoutItem.h;
      const widthDiff = layoutItem.w - oldLayoutItem.w;
      const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
      if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
        layoutItem.h = layoutItem.w / changeCoef;
        placeholder.h = layoutItem.w / changeCoef;
      } else {
        layoutItem.w = layoutItem.h * changeCoef;
        placeholder.w = layoutItem.h * changeCoef;
      }
    },
    []
  );
  const [tooltip, showTooltip] = useState(true);

  function Chart(data: ChartType) {
    //console.log("chart", data);
    switch (data.type) {
      case "line":
        return <LineChart ChartData={data} />;
      case "bar":
        return <BarChart />;
      case "scatter":
        return <ScatterChart />;
      case "gauge":
        return <Gauge ChartData={data} />;
      case "calendar":
        return (
          <div
            className="h-full w-full"
            onMouseEnter={() => showTooltip(true)}
            onMouseLeave={() => {
              showTooltip(false);
              setTimeout(() => showTooltip(true), 50);
            }}
          >
            <Calendar />
          </div>
        );
      case "table":
        return <Table />;
      case "value":
        return <Value />;
      case "text":
        return <Text />;
      case "map":
        return <Map />;
      case "imageMap":
        return <ImageMap />;
      default:
        return <div>Chart</div>;
    }
  }

  function ChartEdit(Editdata: any, id: string) {
    switch (Editdata.type) {
      case "line":
        return (
          <EditLineChart
            id={id}
            remove={onRemoveItem}
            updateChart={updateChart}
            data={Editdata}
          />
        );
      case "bar":
        return <EditBarChart id={id} remove={onRemoveItem} />;
      case "scatter":
        return <EditScatterChart id={id} remove={onRemoveItem} />;
      case "gauge":
        return (
          <EditGauge
            id={id}
            remove={onRemoveItem}
            updateChart={updateChart}
            data={Editdata}
          />
        );
      case "calendar":
        return <EditCalendar id={id} remove={onRemoveItem} />;
      case "table":
        return <EditTable id={id} remove={onRemoveItem} />;
      case "value":
        return <EditValue id={id} remove={onRemoveItem} />;
      case "text":
        return <EditText id={id} remove={onRemoveItem} />;
      case "map":
        return <EditMap id={id} remove={onRemoveItem} />;
      case "imageMap":
        return <EditImageMap id={id} remove={onRemoveItem} />;
      default:
        return <div>Default - {id}</div>;
    }
  }

  return (
    <div className="select-none bg-gray-200 h-full overflow-y-scroll scrollbar-thin">
      <div className="justify-between flex items-center">
        <ChartSelect
          onSelect={(type) => {
            onAddItem(type);
          }}
        />
        <div className="relative inline-block mr-2 align-middle select-none">
          {!editItem ? (
            <button
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-3 w-auto text-sm"
              type="button"
              onClick={() => {
                setEditItem(true);
              }}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ml-3 w-auto text-sm"
                type="button"
                onClick={() => {
                  setEditItem(false);
                }}
              >
                Cancel
              </button>
              <button
                className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                type="button"
                onClick={() => {
                  setEditItem(false);
                  saveToLS("layouts", layout);
                  saveToLS("charts", items);
                }}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
      <ResponsiveReactGridLayout
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={50}
        layouts={{ lg: layout }}
        draggableHandle=".draggable"
        //compactType={null}
        measureBeforeMount={true} //Required to ensure graphs know their dimensions before being mounted
        isDraggable={editItem}
        isResizable={editItem}
        //onResize={handleResize}
      >
        {_.map(items, (el) => createElement(el))}
      </ResponsiveReactGridLayout>
      {tooltip && <ReactTooltip />}
      <EditChartSettings
        chartEditInfo={chartEditInfo}
        showChartEdit={showChartEdit}
        setShowChartEdit={setShowChartEdit}
      />
    </div>
  );
}

function getFromLS(key: string) {
  let ls: any = {};
  if (localStorage) {
    try {
      ls = JSON.parse(localStorage.getItem(key) || "{}");
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key: any, value: any) {
  if (localStorage) {
    localStorage.setItem(
      key,
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

export default ViewDashboard;
