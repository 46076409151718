import { configureStore } from "@reduxjs/toolkit";
import { authAPI } from "../services/authAPI";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authReducer, { clearAuth } from "../features/auth/authSlice";
import userReducer, { clearUser } from "../features/user/userSlice";
import deviceReducer, { clearDevices } from "../features/devices/deviceSlice";
import dataReducer, { clearDataData } from "../features/locations/dataSlice";
import { userAPI } from "../services/userAPI";
import { deviceAPI } from "../services/deviceAPI";
import { dataAPI } from "../services/dataAPI";

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    auth: authReducer,
    [authAPI.reducerPath]: authAPI.reducer,
    user: userReducer,
    [userAPI.reducerPath]: userAPI.reducer,
    devices: deviceReducer,
    [deviceAPI.reducerPath]: deviceAPI.reducer,
    data: dataReducer,
    [dataAPI.reducerPath]: dataAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authAPI.middleware)
      .concat(userAPI.middleware)
      .concat(deviceAPI.middleware)
      .concat(dataAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);

export const doLogout = () => {
  // do other logout stuff here, for example logging out user with backend, etc..

  store.dispatch(clearAuth());
  store.dispatch(clearUser());
  store.dispatch(clearDevices());
  store.dispatch(clearDataData());
  // Let every one of your reducers reset here.
};
