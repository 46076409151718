import React, { useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { IoMdSettings } from "react-icons/io";
import {
  MdLastPage,
  MdFirstPage,
  MdNavigateNext,
  MdNavigateBefore,
} from "react-icons/md";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnFiltersState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";

import type { DeviceType } from "../../services/deviceAPI";
import QuickEditModal from "../../components/modals/QuickEditModal";
import _ from "lodash";

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the ranking info
  addMeta(itemRank);

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const defaultData: DeviceType[] = [];

export default function DeviceTable() {
  const { data: locInfo, isSuccess, isLoading } = useGetDevicesQuery("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [quickEditModal, setQuickEditModal] = useState(false);
  const [quickEditLocation, setQuickEditLocation] = useState<DeviceType>(defaultData[0]);

  useEffect(() => {
    if (isSuccess) {
      setData([...locInfo]);
    }
    return () => {};
  }, [isSuccess, locInfo]);

  const [data, setData] = React.useState([...defaultData]);

  const columns: ColumnDef<DeviceType>[] = [
    {
      accessorFn: (row) => row.name,
      id: "DeviceName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title="Quick Edit"
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              /*setQuickEditModal(true);
              setQuickEditLocation(row.original as DeviceType);*/
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: "Device Name",
    },
    {
      accessorFn: (row) => row.eui,
      id: "DeviceID",
      cell: ({ row, getValue }) => getValue(),
      header: "Device ID",
    },
    {
      accessorFn: (row) => row.lat.toFixed(4) + ", " + row.lng?.toFixed(4),
      id: "DeviceLocation",
      cell: ({ row, getValue }) => getValue(),
      header: "Device Location",
    },
    {
      accessorFn: (row) => row.status.online,
      id: "DeviceStatus",
      cell: ({ row, getValue }) =>
        (getValue() ? "Online" : "Offline") + " " + row.original?.status.last_message_ts,
      header: "Device Status",
    },
  ];

  const table = useReactTable({
    data,
    columns,

    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "DeviceName") {
      if (table.getState().sorting[0]?.id !== "DeviceName") {
        table.setSorting([{ id: "DeviceName", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  return (
    <>
      {quickEditModal ? (
        <QuickEditModal
          locationData={quickEditLocation}
          onCancel={() => setQuickEditModal(false)}
        />
      ) : null}
      <div className="flex h-full rounded bg-white shadow-md select-none">
        <div className="relative shadow-md sm:rounded-lg w-full scrollbar-thin flex justify-between flex-col">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <h1 className="sm:ml-6 ml-2 text-xl font-bold flex items-center">
                Devices
              </h1>
              <div>
                <DebouncedInput
                  value={globalFilter ?? ""}
                  onChange={(value) => setGlobalFilter(String(value))}
                  className="p-2 m-2 font-lg border border-block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-44"
                  placeholder="Search all columns..."
                />
              </div>
            </div>
            <div className="h-table-body-sm sm:h-table-body-lg overflow-y-scroll scrollbar-thin ">
              <table className="w-full text-left text-sm text-gray-500 table-auto">
                <thead className="bg-gray-50 text-xs uppercase text-gray-700 min-h-table-header">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="px-6 py-3"
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex flex-row"
                                    : "",
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <TiArrowSortedUp size={16} />,
                                  desc: <TiArrowSortedDown size={16} />,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.length !== 0 ? (
                    table
                      .getRowModel()
                      .rows //.slice(0, 10)
                      .map((row) => {
                        return (
                          <tr key={row.id} className="hover:bg-gray-50  bg-white ">
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td key={cell.id} className="px-6 py-4">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                  ) : isLoading ? (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No devices found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="m-2">
            <div className="h-2" />
            <div className="flex flex-wrap justify-center sm:justify-start">
              <div className="flex items-center gap-2">
                <button
                  className="border rounded p-1"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  <MdFirstPage
                    size={24}
                    fill={!table.getCanPreviousPage() ? "grey" : "black"}
                  />
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  <MdNavigateBefore
                    size={24}
                    fill={!table.getCanPreviousPage() ? "grey" : "black"}
                  />
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  <MdNavigateNext
                    size={24}
                    fill={!table.getCanNextPage() ? "grey" : "black"}
                  />
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  <MdLastPage
                    size={24}
                    fill={!table.getCanNextPage() ? "grey" : "black"}
                  />
                </button>
                <span className="flex items-center gap-1">
                  <strong>
                    {table.getState().pagination.pageIndex + 1}/{table.getPageCount()}
                  </strong>
                </span>
              </div>
              <div className="flex sm:ml-2">
                <span className="flex items-center gap-1">
                  Go to page:
                  <input
                    type="number"
                    max={table.getPageCount()}
                    min={1}
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      table.setPageIndex(page);
                    }}
                    className="rounded-xl border-1 border-gray-300 w-16 mr-2"
                  />
                </span>
                <select
                  value={table.getState().pagination.pageSize}
                  className="rounded-xl border-1 border-gray-300"
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// A debounced input react component

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}

/*export default function DeviceTable() {
  const { data, isSuccess } = useGetLocationsQuery("");

  return (
    <div className="flex h-full overflow-y-auto overflow-x-hidden rounded bg-white shadow-md select-none">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full scrollbar-thin">
        <table className="w-full text-left text-sm text-gray-500 ">
          <thead className="bg-gray-50 text-xs uppercase text-gray-700 min-h-table-header">
            <tr>
              <th className="px-6 py-3">Device Name</th>
              <th className="px-6 py-3">Device ID</th>
              <th className="px-6 py-3">Latest RSSI</th>
              <th className="px-6 py-3">Current Location</th>
              <th className="px-6 py-3">Latest Battery Level</th>
              <th className="px-6 py-3">View Device</th>
              <th className="px-6 py-3">Delete Device</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {isSuccess &&
              data.map((device: Device) => {
                if (
                  !(
                    device.loc_name?.indexOf("*OLD*") == 0 ||
                    device.loc_name?.indexOf("*OLD1*") == 0
                  )
                ) {
                  return (
                    <TableRow
                      key={device.loc_id}
                      battery={0}
                      id={device.loc_id}
                      location={`${device.lat}, ${device.lng}`}
                      name={device.loc_name}
                      rssi={-120}
                    />
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

type TableRowProps = {
  name: string | null;
  id: string | null;
  rssi: number | null;
  location: string | null;
  battery: number | null;
};

const TableRow = ({ name, id, rssi, location, battery }: TableRowProps) => {
  return (
    <tr className="hover:bg-gray-50  bg-white ">
      <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900 ">
        {name}
      </td>
      <td className="px-6 py-4">{id}</td>
      <td className="px-6 py-4">{rssi}</td>
      <td className="px-6 py-4">{location}</td>
      <td className="px-6 py-4">{battery}</td>
      <td
        className="px-6 py-4 font-medium text-blue-600 hover:underline "
        onClick={() => {
          view(id);
        }}
      >
        View
      </td>
      <td
        className="px-6 py-4 font-medium text-red-600 hover:underline "
        onClick={() => {
          remove(id);
        }}
      >
        Remove
      </td>
    </tr>
  );
};

const view = (id: string | null) => {
  //console.log("view: " + id);
};
const remove = (id: string | null) => {
  //console.log("remove: " + id);
};
*/
