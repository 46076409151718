import React from "react";

type Props = {
  id: string;
  remove: (id: string) => void;
};

function EditText({ id, remove }: Props) {
  return (
    <>
      <div>EditText - {id}</div>
      <button
        type="button"
        onClick={() => remove(id)}
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        {"Delete"}
      </button>
    </>
  );
}

export default EditText;
