import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { ChartType, GaugeData, Language } from "..";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { DataType, selectData } from "../../../../features/locations/dataSlice";
import { useGetDataMutation } from "../../../../services/dataAPI";
import { v4 as uuidv4 } from "uuid";
import { format, I18nSettings } from "fecha";

type format = (date: Date, format?: string, i18n?: I18nSettings) => string;

type Props2 = {
  i: string;
  title: Language;
  chartData: GaugeData[];
};

type Props = {
  ChartData: ChartType;
};

function Gauge({ ChartData }: Props) {
  const { i: id, title, chartData }: Props2 = ChartData;
  if (chartData.length === 0) {
    chartData.push({
      label: "",
      colour: "#" + Math.floor(Math.random() * 16777215).toString(16),
      phenomena: "new",
      ringID: uuidv4(),
      deviceID: "new",
      unit: "",
      min: 0,
      max: 10,
    });
  }

  const nodeData = useAppSelector(selectData);
  const [value, setValue] = useState(0);
  const [getData, { isLoading }] = useGetDataMutation();
  const [readingTime, setReadingTime] = useState<string>();
  //console.log("chartData", chartData);

  useEffect(() => {
    //console.log("Gauge", nodeData.data[chartData[0].deviceID as keyof DataType]);
    //console.log(chartData[0].deviceID);
    if (nodeData) {
      if (nodeData.data[chartData[0].deviceID as keyof DataType] !== undefined) {
        setReadingTime(
          format(
            new Date(
              nodeData.data[chartData[0].deviceID as keyof DataType][0].ts.toString()
            ),
            "[on] DD/MM/YYYY [at] HH:mm:ss"
          )
        );
        console.log("readingTime", readingTime);
        let latestValue =
          nodeData.data[chartData[0].deviceID as keyof DataType][0].data_points[
            ChartData.chartData[0].phenomena
          ][0];
        setValue(latestValue);
      } else {
        if (chartData[0].deviceID !== undefined && chartData[0].deviceID !== "") {
          getData({ eui: chartData[0].deviceID, readings: 0 });
          setInterval(() => {
            console.log(chartData[0].deviceID);
            if (nodeData.data[chartData[0].deviceID as keyof DataType] !== undefined) {
              if (nodeData.data[chartData[0].deviceID as keyof DataType].length < 2) {
                console.log(chartData[0].deviceID, nodeData.data);

                getData({ eui: chartData[0].deviceID, readings: 0 });
              }
            } else {
              getData({ eui: chartData[0].deviceID, readings: 0 });
            }
          }, 120000);
        }
      }
    }
  }, [ChartData, chartData, nodeData]);

  const gaugeData = [
    {
      value: CalculateValue(value, chartData[0].max ?? 20, chartData[0].min ?? 0),
      title: {
        offsetCenter: ["0", "-25"],
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ["0", "0"],
      },
    },
  ];

  const options = {
    series: [
      {
        type: "gauge",
        startAngle: 225,
        endAngle: -45,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#464646",
            color: chartData[0].colour,
          },
        },

        axisLine: {
          lineStyle: {
            width: 15,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          formatter: function (value: number) {
            return CalculateTicks(chartData[0].max, chartData[0].min, value);
          },
          distance: 10,
        },
        data: gaugeData,

        title: {
          fontSize: 14,
        },
        detail: {
          width: "auto",
          height: 14,
          fontSize: 14,
          fontWeight: "bold",
          color: chartData[0].colour,
          borderColor: chartData[0].colour,
          borderRadius: 20,
          borderWidth: 1,
          formatter: value + (chartData[0].unit ?? ""),
        },
      },
    ],
  };
  return (
    <>
      <p className="text-center font-bold">{title.en}</p>
      <p className="text-center font-bold">{readingTime}</p>
      <ReactECharts
        style={{ height: "calc(100% - 2rem)", width: "100%" }}
        opts={{ renderer: "svg" }}
        option={options}
      />
    </>
  );
}

export default Gauge;

function CalculateValue(value: number, max: number, min: number) {
  return ((value - min) / (max - min)) * 100;
}

function CalculateTicks(max: number, min: number, value: number) {
  //console.log(max, min, value, (max + min) / 2);
  max = parseInt(max.toString());
  min = parseInt(min.toString());
  value = parseInt(value.toString());
  switch (value) {
    case 100:
      return max;
    case 0:
      return min;
    case 50:
      return max + min == 0 ? 0 : (max + min) / 2;
    default:
      return;
  }
}
