import { MdKeyboardArrowDown } from "react-icons/md";
import {
  AiOutlineLineChart,
  AiOutlineBarChart,
  AiOutlineDotChart,
  AiOutlineFieldNumber,
} from "react-icons/ai";
import { TbGauge } from "react-icons/tb";
import { IoCalendarSharp, IoMap, IoImageOutline } from "react-icons/io5";
import { RiTableLine } from "react-icons/ri";
import { BsCardText } from "react-icons/bs";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type MenuItemProps = {
  active: boolean;
};

type ChartSelectProps = {
  onSelect: (chartType: string) => void;
};
export default function ChartSelect({ onSelect }: ChartSelectProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          Add new chart
          <MdKeyboardArrowDown
            className="-mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-over-map translate-x-20">
          <div className="py-1">
            <NewItem
              name="Line Chart"
              icon={<AiOutlineLineChart size={20} />}
              onClick={() => {
                onSelect("line");
              }}
            />
            <NewItem
              name="Bar Chart"
              icon={<AiOutlineBarChart size={20} />}
              onClick={() => {
                onSelect("bar");
              }}
            />
            <NewItem
              name="Scatter Chart"
              icon={<AiOutlineDotChart size={20} />}
              onClick={() => {
                onSelect("scatter");
              }}
            />
          </div>
          <div className="py-1">
            <NewItem
              name="Gauge"
              icon={<TbGauge size={20} />}
              onClick={() => {
                onSelect("gauge");
              }}
            />
            <NewItem
              name="Calendar"
              icon={<IoCalendarSharp size={20} />}
              onClick={() => {
                onSelect("calendar");
              }}
            />
            <NewItem
              name="Table"
              icon={<RiTableLine size={20} />}
              onClick={() => {
                onSelect("table");
              }}
            />
            <NewItem
              name="Value"
              icon={<AiOutlineFieldNumber size={20} />}
              onClick={() => {
                onSelect("value");
              }}
            />
            <NewItem
              name="Text"
              icon={<BsCardText size={20} />}
              onClick={() => {
                onSelect("text");
              }}
            />
          </div>
          <div className="py-1">
            <NewItem
              name="Land Map"
              icon={<IoMap size={20} />}
              onClick={() => {
                onSelect("map");
              }}
            />
            <NewItem
              name="Image Map"
              icon={<IoImageOutline size={20} />}
              onClick={() => {
                onSelect("imageMap");
              }}
            />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

type NewItemProps = {
  name: string;
  onClick: () => void;
  icon: React.ReactNode;
};
const NewItem = ({ name, icon, onClick }: NewItemProps) => {
  return (
    <Menu.Item>
      {({ active }: MenuItemProps) => (
        <div
          onClick={onClick}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "px-4 py-2 text-sm cursor-pointer flex flex-row items-center"
          )}
        >
          {icon}
          <div className="ml-2 font-bold">{name}</div>
        </div>
      )}
    </Menu.Item>
  );
};
