import ReactECharts from "echarts-for-react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useRef, useState } from "react";
import { format, I18nSettings } from "fecha";
import { ChartType, LineChartData } from "..";
import _ from "lodash";
import { DataType, selectData } from "../../../../features/locations/dataSlice";
import {
  DataPoint,
  UplinkData,
  useGetDataMutation,
} from "../../../../services/dataAPI";

type format = (date: Date, format?: string, i18n?: I18nSettings) => string;
type Props = {
  ChartData: ChartType;
};

function LineChart({ ChartData }: Props) {
  const nodeData = useAppSelector(selectData);
  const [getData, { isLoading }] = useGetDataMutation();
  const chartRef = useRef<ReactECharts>(null);
  const [lines, setLines] = useState<LineChartData[]>(ChartData.chartData);

  const timeSeriesData = (
    phenomena: string | undefined,
    deviceid: string | undefined
  ) => {
    if (nodeData.data[deviceid as keyof DataType] !== undefined) {
      var arr = _.values(nodeData.data[deviceid as keyof DataType]);
      return arr.map((element: UplinkData, index: number) => {
        if (typeof element.ts === "string" && phenomena !== undefined) {
          const date = new Date(element.ts);
          var newData: (number | Date)[];
          //console.log(element);
          if (
            element.data_points !== undefined &&
            element?.data_points !== null
          ) {
            if (
              element?.data_points[phenomena] !== undefined &&
              element?.data_points[phenomena] !== null
            ) {
              if (element.data_points[phenomena].length > 0) {
                newData = [date, element.data_points[phenomena][0]];
              } else {
                newData = [];
              }
            } else {
              newData = [];
            }
          } else {
            newData = [];
          }
          return newData;
        }
      });
    } else {
      console.log("isLoading", isLoading);
      if (deviceid !== undefined && !isLoading) {
        getData({ eui: deviceid, readings: 0 });
        setInterval(() => {
          getData({ eui: deviceid, readings: 0 });
        }, 120000);
      }
    }
  };

  useEffect(() => {
    setLines(ChartData.chartData);
  }, [ChartData.chartData]);

  useEffect(() => {
    if (chartRef && chartRef.current && nodeData) {
      chartRef.current?.getEchartsInstance().showLoading("default", {
        text: "Retrieving Data",
        color: "#000099",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0.8)",
        zlevel: 0,
        // Font size. Available since `v4.8.0`.
        fontSize: 12,
        // Show an animated "spinner" or not. Available since `v4.8.0`.
        showSpinner: true,
        // Radius of the "spinner". Available since `v4.8.0`.
        spinnerRadius: 10,
        // Line width of the "spinner". Available since `v4.8.0`.
        lineWidth: 5,
        // Font thick weight. Available since `v5.0.1`.
        fontWeight: "normal",
        // Font style. Available since `v5.0.1`.
        fontStyle: "normal",
        // Font family. Available since `v5.0.1`.
        fontFamily: "sans-serif",
      });
      chartRef.current?.getEchartsInstance().setOption({
        dataZoom: [
          {
            type: "inside",
            start: 90,
            end: 100,
          },
          {
            start: 90,
            end: 100,
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current && nodeData) {
      if (lines.length > 0) {
        //console.log("lines", lines);
        const series = lines.map((line) => {
          if (line !== undefined) {
            return {
              name: line.label,
              type: "line",
              symbol: "none",
              sampling: "lttb",
              smooth: line.smoothed ?? false,
              connectNulls: line.connectedNulls ?? true,
              lineStyle: {
                color: line.colour,
                type: line.style,
              },
              itemStyle: {
                color: line.colour,
              },
              data: timeSeriesData(line.phenomena, line.deviceID),
            };
          }
        });
        //console.log("options before", chartRef.current?.getEchartsInstance().getOption());
        //console.log(series);
        /*chartRef.current?.getEchartsInstance().setOption({
          series: "please edit options",
        });*/
        //console.log("options middle", chartRef.current?.getEchartsInstance().getOption());
        if (nodeData.data[lines[0].deviceID as keyof DataType] !== undefined) {
          console.log("nodeData", nodeData);
          chartRef.current?.getEchartsInstance().hideLoading();
        }
        chartRef.current?.getEchartsInstance().setOption({
          series: series,
        });
        //console.log("options after", chartRef.current?.getEchartsInstance().getOption());
      }
    }
  }, [nodeData, lines]);

  const options = {
    title: {
      text: ChartData.title.en,
    },
    legend: { type: "scroll" },
    grid: {
      left: "3%",
      right: "4%",
      bottom: 50,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
    },
    animation: false,
    toolbox: {
      feature: {
        //restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
      alignTicks: "value",
      minorTick: { show: true },
      splitLine: { show: true },
    },
    yAxis: [
      {
        type: "value",
        name: "line",
        axisLabel: {
          formatter: "{value}",
        },
        scale: false,
      },
    ],
    dataZoom: [],
  };
  return (
    <ReactECharts
      style={{ height: "100%", width: "100%" }}
      opts={{ renderer: "svg" }}
      option={options}
      ref={chartRef}
    />
  );
}

export default LineChart;
