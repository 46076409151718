import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ChartType, LineChartData } from "..";
import { FormInput } from "../../../FormInput";
import NewLineMenu from "./NewLineMenu";
import { v4 as uuidv4 } from "uuid";
import { useGetDataMutation } from "../../../../services/dataAPI";
//Things that can be edited in the line chart
//Title (Translations?), line Data[] (name, data, colour, units), Max Time-frame, Time format, Reference Lines[] (name, value, colour)

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
};

function EditLineChart({ id, remove, updateChart, data }: Props) {
  const [title, setTitle] = useState(data.title.en);
  const [timeFormat, setTimeFormat] = useState(data.timeFormat);
  const [lines, setLines] = useState<LineChartData[]>(data.chartData);

  useGetDataMutation();

  useEffect(() => {
    setTitle(data.title.en);
    setTimeFormat(data.timeFormat);
    setLines(data.chartData);
  }, [data]);

  const saveChartSettings = () => {
    updateChart({
      i: id,
      type: "line",
      title: { en: title },
      timeFormat: "",
      chartData: lines,
    });
  };

  const addNewLine = ({
    label,
    phenomena,
    colour,
    lineID,
    style,
    connectedNulls,
    deviceID,
    smoothed,
    unit,
  }: LineChartData) => {
    setLines(
      _.concat(lines, {
        label,
        phenomena,
        colour,
        lineID,
        style,
        connectedNulls,
        deviceID,
        smoothed,
        unit,
      })
    );
  };

  const onRemoveLine = (lineID: any) => {
    //console.log("removing", lineID);
    setLines(_.reject(lines, { lineID: lineID }));
  };
  //console.log("lines", lines);

  return (
    <div className="h-full">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="px-4 py-6 bg-white">
            <h1 className="text-lg font-medium text-gray-900">Edit Line Chart</h1>
          </div>
          <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
            <h2 className="text-lg font-bold text-black">{id}</h2>
            <div className="my-1 h-px bg-gray-400 "></div>

            <input
              type="text"
              value={title !== null ? title : ""}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </div>

          <div>
            {lines.map((line, index) => (
              <NewLineMenu
                index={index}
                line={line}
                key={index}
                removeLine={onRemoveLine}
                saveLineData={(line: LineChartData) => {
                  setLines(
                    _.map(lines, (currentLine: LineChartData) => {
                      if (currentLine.lineID === line.lineID) {
                        currentLine.label = line.label;
                        currentLine.colour = line.colour;
                        currentLine.phenomena = line.phenomena;
                        currentLine.style = line.style;
                        currentLine.connectedNulls = line.connectedNulls;
                        currentLine.deviceID = line.deviceID;
                        currentLine.smoothed = line.smoothed;
                        currentLine.unit = line.unit;
                      }
                      return currentLine;
                    })
                  );
                }}
              />
            ))}
          </div>
          <button
            onClick={() =>
              addNewLine({
                label: "",
                colour: "#" + Math.floor(Math.random() * 16777215).toString(16),
                phenomena: "new",
                lineID: uuidv4(),
                style: "solid",
                connectedNulls: true,
                deviceID: "new",
                smoothed: false,
                unit: "",
              })
            }
          >
            Add new Line
          </button>
        </div>

        <div>
          <button
            type="button"
            onClick={() => remove(id)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {"Delete"}
          </button>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={() => saveChartSettings()}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {"Save"}
            </button>
            {/*<button
              type="button"
              onClick={async () => getData({ eui: "0080e11505046436" })}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-10       0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              onClick={async () => getData({ eui: "0080e11505046247" })}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-10       0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {"Cancel2"}
            </button>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditLineChart;

function addTimeZero(time: string | number) {
  if (time < 10) {
    return "0" + time;
  }
  return time;
}
