import React from "react";
import { Outlet } from "react-router-dom";
import { useVerifyEmailMutation } from "../../services/authAPI";

function GroupIndex() {
  const [
    verifyEmail,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error: loginError,
    },
  ] = useVerifyEmailMutation();
  return (
    <div className="h-full w-full">
      <Outlet />
    </div>
  );
}

export default GroupIndex;
