import { setupListeners } from "@reduxjs/toolkit/dist/query";
import _, { map } from "lodash";
import React, { useEffect, useState } from "react";
import { MdLineStyle } from "react-icons/md";
import { Config } from "tailwindcss";
import { LineChartData } from "..";
import { useAppSelector } from "../../../../app/hooks";
//import { selectUser } from "../../../../features/user/userSlice";
import { DeviceType, useGetDevicesQuery } from "../../../../services/deviceAPI";

type Props = {
  line: LineChartData;
  index: number;
  saveLineData: (line: LineChartData) => void;
  removeLine: (index: string) => void;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

function NewLineMenu({ line: currentLine, index, saveLineData, removeLine }: Props) {
  const [line, setLine] = useState<LineChartData>(currentLine);
  const { data: locInfo, isSuccess, isLoading } = useGetDevicesQuery("");
  //let { group } = useAppSelector(selectUser);
  const [deviceData, setDeviceData] = useState<DeviceType[]>([]);
  const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);

  useEffect(() => {
    if (isSuccess) {
      setDeviceData(locInfo);
    }
    return () => {};
  }, [isSuccess, locInfo]);

  useEffect(() => {
    setLine(currentLine);
  }, [currentLine]);

  useEffect(() => {
    saveLineData(line);
  }, [line]);

  useEffect(() => {
    deviceData.forEach((device) => {
      if (device.eui === line.deviceID) {
        const keys = _.keys(device.configuration);
        //console.log(keys);
        var phenom: FormInputData[] = [];
        keys.forEach((key) =>
          device.configuration[key].forEach((element: any) =>
            phenom.push({
              optionValue: key,
              optionName: element.description,
            })
          )
        );
        //console.log(phenom);
        setPhenomenaSelectData(phenom);
      }
    });
    //console.log(phenomenaSelectData);
  }, [line.deviceID]);

  let userDeviceData: FormInputData[] =
    deviceData?.map((device: DeviceType) => {
      return {
        optionValue: device.eui !== null ? device.eui : "",
        optionName: device.name !== null ? device.name : "",
      };
    }) ?? [];

  //console.log("line", line);
  return (
    <div key={index} className="rounded-md bg-gray-200 p-1 flex flex-col my-2">
      <input
        type="text"
        placeholder="Line Name"
        value={line.label}
        onChange={(event) => setLine({ ...line, label: event.target.value })}
      />
      <select
        value={line.deviceID ?? "new"}
        onChange={(event) => {
          setLine({ ...line, deviceID: event.target.value });
        }}
      >
        <option value={"new"} disabled>
          Select a device
        </option>
        {userDeviceData.map((device, index) => (
          <option key={index} value={device.optionValue}>
            {device.optionName}
          </option>
        ))}
      </select>
      <select
        disabled={line.deviceID !== "new" ? false : true}
        value={line.phenomena ?? "new"}
        onChange={(event) => {
          setLine({ ...line, phenomena: event.target.value });
        }}
      >
        <option value={"new"} disabled>
          Select a phenomena
        </option>
        {phenomenaSelectData.map((option, index) => (
          <option key={index} value={option.optionValue}>
            {option.optionName}
          </option>
        ))}
      </select>

      <div className="flex flex-row justify-evenly">
        <div>
          <input
            type="radio"
            id="solid"
            name={`lineStyle${index}`}
            value="solid"
            defaultChecked={line.style === "solid"}
            onChange={() => setLine({ ...line, style: "solid" })}
          />
          <label>Solid</label>
        </div>
        <div>
          <input
            type="radio"
            id="dashed"
            name={`lineStyle${index}`}
            value="dashed"
            defaultChecked={line.style === "dashed"}
            onChange={() => setLine({ ...line, style: "dashed" })}
          />
          <label>Dashed</label>
        </div>
        <div>
          <input
            type="radio"
            id="dotted"
            name={`lineStyle${index}`}
            value="dotted"
            defaultChecked={line.style === "dotted"}
            onChange={() => setLine({ ...line, style: "dotted" })}
          />
          <label>Dotted</label>
        </div>
      </div>
      <input
        type="color"
        value={line.colour}
        onChange={(event) => setLine({ ...line, colour: event.target.value })}
      />
      <button onClick={() => removeLine(line.lineID)}>Remove</button>
    </div>
  );
}

export default NewLineMenu;
