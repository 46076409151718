export type LanguageText = {
  form: {
    email: string;
    password: string;
    confirmPassword: string;
    username: string;
    remember: string;
  };
  buttons: {
    login: string;
    register: string;
    forgotPassword: string;
    notGotAccount: string;
    GotAccount: string;
  };
  formErrors: {
    usernameError: string;
    emailError: string;
    passwordError: string;
    confirmPasswordError: string;
    formError: string;
  };
  termsAndConditions: {
    title: string;
    body: React.ReactNode;
    accept: string;
    cancel: string;
  };
};

export const English: LanguageText = {
  form: {
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    username: "Username",
    remember: "Remember Me",
  },
  buttons: {
    login: "Login",
    register: "Register",
    forgotPassword: "Forgotten Password",
    notGotAccount: "Don't have an account yet? Register here.",
    GotAccount: "Already have an account? Login here.",
  },
  formErrors: {
    usernameError: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    formError: "",
  },
  termsAndConditions: {
    title: "Terms and Conditions",
    body: (
      <>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed quanta sit
        alias, nunc tantum possitne esse tanta.Nulla profecto est, quin suam vim
        retineat a primo adextremum. <b>Duo Reges: constructio interrete.</b>{" "}
        Atquihaec patefactio quasi rerum opertarum, cum quid quidquesit
        aperitur, definitio est. Quid enim mihi potest esseoptatius quam cum
        Catone, omnium virtutum auctore, devirtutibus disputare? Animi enim
        quoque dolorespercipiet omnibus partibus maiores quam corporis.
        Verumesto: verbum ipsum voluptatis non habet dignitatem, necnos fortasse
        intellegimus.
      </>
    ),
    accept: "Accept",
    cancel: "Cancel",
  },
};

export const French: LanguageText = {
  form: {
    email: "French-Email",
    password: "French-Password",
    confirmPassword: "French-Confirm Password",
    username: "French-Username",
    remember: "French-Remember Me",
  },
  buttons: {
    login: "French-Login",
    register: "French-Register",
    forgotPassword: "French-Forgotten Password",
    notGotAccount: "French-Don't have an account yet? Register here.",
    GotAccount: "French-Already have an account? Login here.",
  },
  formErrors: {
    usernameError: "French-",
    emailError: "French-",
    passwordError: "French-",
    confirmPasswordError: "French-",
    formError: "French-",
  },
  termsAndConditions: {
    title: "French-Terms and Conditions",
    body: (
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed quanta sit
        alias, nunc tantum possitne esse tanta.Nulla profecto est, quin suam vim
        retineat a primo adextremum. <b>Duo Reges: constructio interrete.</b>{" "}
        Atquihaec patefactio quasi rerum opertarum, cum quid quidquesit
        aperitur, definitio est. Quid enim mihi potest esseoptatius quam cum
        Catone, omnium virtutum auctore, devirtutibus disputare? Animi enim
        quoque dolorespercipiet omnibus partibus maiores quam corporis.
        Verumesto: verbum ipsum voluptatis non habet dignitatem, necnos fortasse
        intellegimus.
      </div>
    ),
    accept: "French-Accept",
    cancel: "French-Cancel",
  },
};
//export const Spanish: LanguageText = {};
//export const German: LanguageText = {};
//export const Italian: LanguageText = {};
