import { ReactNode } from "react";
import { FormInput } from "../FormInput";
import Spinner from "../Spinner";

type Props = {
  onSubmit: (...args: any) => any;
  onCancel: (...args: any) => any;
  Title: string;
  Loading: boolean;
  Body: ReactNode | string;
  Form: FormInputProps[];
  AcceptButton: string;
  CancelButton: string;
};

type FormInputProps = {
  htmlFor: string;
  label: string;
  type?: string;
  value: any;
  title?: boolean;
  onChange?: (...args: any) => any;
  error?: string;
  required?: boolean;
  tooltip?: string;
  data?: FormInputData[];
  autoComplete?: string;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

function AcceptModal({
  onSubmit,
  onCancel,
  Title,
  Loading,
  Body,
  Form,
  AcceptButton,
  CancelButton,
}: Props) {
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <form onSubmit={onSubmit}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      {Title}
                    </h3>
                    <div className="mt-2">
                      <div className="pb-2">{Body}</div>

                      {Form.map((input, index) => (
                        <FormInput
                          htmlFor={input.htmlFor}
                          label={input.label}
                          value={input.value}
                          onChange={input.onChange}
                          error={input.error}
                          required={input.required}
                          tooltip={input.tooltip}
                          data={input.data}
                          key={index}
                          autoComplete={input.autoComplete}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {Loading ? <Spinner colour="fill-green-600" /> : AcceptButton}
                </button>
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-10       0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {CancelButton}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptModal;
