import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type UserState = {
  username: string | null;
  email: string | null;
  phoneNumber: number | null;
  id: string | null;
  group: Partner[] | null;
};

type Partner = {
  can_create_partner: boolean;
  partner_id: string;
  partner_name: string;
  sms_enabled: boolean;
};

const initialState: UserState = {
  username: null,
  email: null,
  phoneNumber: null,
  id: null,
  group: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: any, action: PayloadAction<UserState>) => {
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
      state.id = action.payload.id;
      state.group = action.payload.group;
    },
    clearUser: (state: any) => {
      state.username = null;
      state.email = null;
      state.phoneNumber = null;
      state.id = null;
      state.group = null;
    },
  },
});

export const selectUser = (state: RootState) => state.user;

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
