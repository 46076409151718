import { Tooltip } from "flowbite-react";
import { useState } from "react";

type FormInputProps = {
  htmlFor: string;
  label: string;
  type?: string;
  value: any;
  title?: boolean;
  onChange?: (...args: any) => any;
  error?: string;
  required?: boolean;
  tooltip?: string;
  data?: FormInputData[];
  autoComplete?: string;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

export function FormInput({
  htmlFor,
  label,
  type = "text",
  value,
  title = true,
  onChange = () => {},
  error = "",
  required = false,
  tooltip = "",
  data = [],
  autoComplete,
}: FormInputProps) {
  const [inputValue, setInputValue] = useState(value);
  const Label = (label: string, htmlFor: string) => {
    return (
      <label htmlFor={htmlFor} className="text-blue-600 font-semibold">
        {label}
      </label>
    );
  };
  return (
    <div className="mb-4">
      {title ? (
        tooltip ? (
          <Tooltip content={tooltip} trigger="hover">
            {Label(label, htmlFor)}
          </Tooltip>
        ) : (
          Label(label, htmlFor)
        )
      ) : null}
      {type === "select" ? (
        <select
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          id={htmlFor}
          name={htmlFor}
          placeholder={label}
          className={`w-full p-2 rounded-xl  border-2 ${
            error ? "border-red-600" : "border-gray-300"
          }`}
          value={inputValue}
        >
          {data.map((item, index) => (
            <option key={index} value={item?.optionValue}>
              {item.optionName}
            </option>
          ))}
        </select>
      ) : (
        <input
          required={required}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          type={type}
          id={htmlFor}
          name={htmlFor}
          autoComplete={autoComplete}
          placeholder={label}
          className={`w-full p-2 rounded-xl  border-2 min-h-[2.75rem] ${
            error ? "border-red-600" : "border-gray-300"
          }`}
          value={inputValue}
        ></input>
      )}
      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
}
