import { setupListeners } from "@reduxjs/toolkit/dist/query";
import _, { map } from "lodash";
import React, { useEffect, useState } from "react";
import { MdLineStyle } from "react-icons/md";
import { Config } from "tailwindcss";
import { GaugeData, LineChartData } from "..";
import { useAppSelector } from "../../../../app/hooks";
//import { selectUser } from "../../../../features/user/userSlice";
import { DeviceType, useGetDevicesQuery } from "../../../../services/deviceAPI";

type Props = {
  ring: GaugeData;
  index: number;
  saveRingData: (line: GaugeData) => void;
  removeRing: (index: string) => void;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

function NewRingMenu({ ring: currentRing, index, saveRingData, removeRing }: Props) {
  const [ring, setRing] = useState<GaugeData>(currentRing);
  const { data: locInfo, isSuccess, isLoading } = useGetDevicesQuery("");
  //let { group } = useAppSelector(selectUser);
  const [deviceData, setDeviceData] = useState<DeviceType[]>([]);
  const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);

  useEffect(() => {
    if (isSuccess) {
      setDeviceData(locInfo);
    }
    return () => {};
  }, [isSuccess, locInfo]);

  useEffect(() => {
    setRing(currentRing);
  }, [currentRing]);

  useEffect(() => {
    saveRingData(ring);
  }, [ring]);

  useEffect(() => {
    deviceData.forEach((device) => {
      if (device.eui === ring.deviceID) {
        const keys = _.keys(device.configuration);
        //console.log(keys);
        var phenom: FormInputData[] = [];
        keys.forEach((key) =>
          device.configuration[key].forEach((element: any) =>
            phenom.push({
              optionValue: key,
              optionName: element.description,
            })
          )
        );
        //console.log(phenom);
        setPhenomenaSelectData(phenom);
      }
    });
    //console.log(phenomenaSelectData);
  }, [ring.deviceID]);

  let userDeviceData: FormInputData[] =
    deviceData?.map((device: DeviceType) => {
      return {
        optionValue: device.eui !== null ? device.eui : "",
        optionName: device.name !== null ? device.name : "",
      };
    }) ?? [];

  //console.log("ring", ring);
  return (
    <div key={index} className="rounded-md bg-gray-200 p-1 flex flex-col my-2">
      Ring Name
      <input
        type="text"
        placeholder="Line Name"
        value={ring.label}
        onChange={(event) => setRing({ ...ring, label: event.target.value })}
      />
      Device
      <select
        value={ring.deviceID ?? "new"}
        onChange={(event) => {
          setRing({ ...ring, deviceID: event.target.value });
        }}
      >
        <option value={"new"} disabled>
          Select a device
        </option>
        {userDeviceData.map((device, index) => (
          <option key={index} value={device.optionValue}>
            {device.optionName}
          </option>
        ))}
      </select>
      Phenomena
      <select
        disabled={ring.deviceID !== "new" ? false : true}
        value={ring.phenomena ?? "new"}
        onChange={(event) => {
          setRing({ ...ring, phenomena: event.target.value });
        }}
      >
        <option value={"new"} disabled>
          Select a phenomena
        </option>
        {phenomenaSelectData.map((option, index) => (
          <option key={index} value={option.optionValue}>
            {option.optionName}
          </option>
        ))}
      </select>
      Colour
      <input
        type="color"
        value={ring.colour}
        onChange={(event) => setRing({ ...ring, colour: event.target.value })}
      />
      {/*<button onClick={() => removeRing(ring.ringID)}>Remove</button>*/}
    </div>
  );
}

export default NewRingMenu;
