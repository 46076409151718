import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthState, login } from "../features/auth/authSlice";
import { setUser, UserState } from "../features/user/userSlice";

type Partner = {
  can_create_partner: boolean;
  partner_id: string;
  partner_name: string;
  sms_enabled: boolean;
};

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("wf-tkn", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: (userID: string) => {
        return {
          url: "/admin/user/" + userID + "/info",
        };
      },
      transformResponse: (result: {
        status: string;
        data: {
          user_id: string;
          partner_id: string;
          user_name: string;
          user_email: string;
          user_phone: string;
          user_level: string;
          user_type: string;
          user_tmp_pwd: string;
          token: string;
          partners: Partner[];
        };
        token: string;
      }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            setUser({
              username: data.user_name,
              email: data.user_email,
              phoneNumber: parseInt(data.user_phone),
              id: data.user_id,
              group: data.partners,
            })
          );
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetUserInfoQuery } = userAPI;
