import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ChartType, GaugeData } from "..";

import { v4 as uuidv4 } from "uuid";
import NewRingMenu from "./NewRingMenu";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
};

function EditGauge({ id, remove, updateChart, data }: Props) {
  const [title, setTitle] = useState(data.title.en);
  const [rings, setRings] = useState<GaugeData[]>(data.chartData);
  const [min, setMin] = useState(data.chartData.min);
  const [max, setMax] = useState(data.chartData.max);
  //console.log("rings", rings);
  useEffect(() => {
    setTitle(data.title.en);
    setRings(data.chartData);
    setMin(rings[0].min);
    setMax(rings[0].max);
  }, [data]);

  const saveChartSettings = () => {
    rings[0].min = min;
    rings[0].max = max;
    updateChart({
      i: id,
      type: "gauge",
      title: { en: title },
      timeFormat: "",
      chartData: rings,
    });
  };

  const addNewRing = ({
    label,
    phenomena,
    colour,
    deviceID,
    ringID,
    unit,
  }: GaugeData) => {
    setRings(
      _.concat(rings, {
        label,
        phenomena,
        colour,
        deviceID,
        unit,
        ringID,
        min,
        max,
      })
    );
  };

  const onRemoveRing = (ringID: any) => {
    //console.log("removing", ringID);
    setRings(_.reject(rings, { ringID: ringID }));
  };

  return (
    <div className="h-full">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="px-4 py-6 bg-white">
            <h1 className="text-lg font-medium text-gray-900">Edit Gauge</h1>
          </div>
          <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
            <h2 className="text-lg font-bold text-black">{id}</h2>
            <div className="my-1 h-px bg-gray-400 "></div>
            Title
            <input
              placeholder="Title"
              type="text"
              value={title !== null ? title : ""}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
            Min Value
            <input
              placeholder="Min Value"
              autoComplete="off"
              type="number"
              value={min}
              onChange={(event) => {
                setMin(event.target.value);
              }}
            />
            Max Value
            <input
              placeholder="Max Value"
              autoComplete="off"
              type="number"
              value={max}
              onChange={(event) => {
                setMax(event.target.value);
              }}
            />
          </div>

          <div>
            {rings.map((ring, index) => (
              <NewRingMenu
                index={index}
                ring={ring}
                key={index}
                removeRing={onRemoveRing}
                saveRingData={(ring: GaugeData) => {
                  setRings(
                    _.map(rings, (currentRing: GaugeData) => {
                      if (currentRing.ringID === ring.ringID) {
                        currentRing.label = ring.label;
                        currentRing.colour = ring.colour;
                        currentRing.phenomena = ring.phenomena;
                        currentRing.deviceID = ring.deviceID;
                        currentRing.unit = ring.unit;
                        currentRing.min = ring.min;
                        currentRing.max = ring.max;
                      }
                      console.log("currentRing", currentRing);
                      return currentRing;
                    })
                  );
                }}
              />
            ))}
          </div>
          {/*
            <button
              onClick={() =>
                addNewRing({
                  label: "",
                  colour: "#" + Math.floor(Math.random() * 16777215).toString(16),
                  phenomena: "new",
                  ringID: uuidv4(),
                  deviceID: "new",
                  unit: "",
                  min: 0,
                  max: 10,
                })
              }
            >
              Add new ring
            </button>
            */}
        </div>

        <div>
          <button
            type="button"
            onClick={() => remove(id)}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {"Delete"}
          </button>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={() => saveChartSettings()}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {"Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditGauge;
