import ReactECharts from "echarts-for-react";
import _ from "lodash";
const colour: string = "#000099";

function Calendar() {
  let maxValue = _.min(data);
  let maxDate = _.maxBy(data, function (o) {
    return new Date(o[0]);
  });
  let minDate = _.minBy(data, function (o) {
    return new Date(o[0]);
  });

  if (maxValue === undefined) {
    maxValue = ["", 0];
  }
  if (maxDate === undefined) {
    maxDate = ["2019-01-01", 0];
  }
  if (minDate === undefined) {
    minDate = ["2019-01-01", 0];
  }

  const options = {
    title: {
      top: 5,
      left: "center",
      text: "Daily Step Count",
    },
    tooltip: {},
    visualMap: {
      min: 0,
      max: maxValue[1],
      type: "piecewise",
      orient: "horizontal",
      left: "center",
      top: 40,
    },
    calendar: {
      top: 95,
      left: 30,
      right: 30,
      cellSize: [13, 13],
      range: [minDate[0], maxDate[0]],
      itemStyle: {
        borderWidth: 0.5,
      },
      dayLabel: {
        firstDay: 1,
        nameMap: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
      monthLabel: {
        nameMap: "cn",
        margin: 10,
      },
      //yearLabel: { show: true },
    },
    series: {
      type: "heatmap",
      coordinateSystem: "calendar",
      data: data,
    },
  };
  return (
    <ReactECharts
      style={{ height: "100%", width: "100%" }}
      opts={{ renderer: "svg" }}
      option={options}
    />
  );
}

export default Calendar;

const data = [
  ["2020-05-24", 259],
  ["2021-06-09", 324],
  ["2021-08-19", 320],
  ["2022-04-22", 10],
  ["2020-02-29", 88],
  ["2020-07-31", 345],
  ["2019-11-29", 22],
  ["2019-11-17", 170],
  ["2021-08-18", 29],
  ["2021-11-03", 87],
  ["2019-08-10", 157],
  ["2019-05-20", 38],
  ["2021-11-12", 273],
  ["2021-03-31", 154],
  ["2020-10-20", 364],
  ["2021-07-31", 312],
  ["2021-12-05", 399],
  ["2020-08-26", 203],
  ["2022-02-18", 308],
  ["2019-08-24", 315],
  ["2021-12-22", 2],
  ["2021-12-25", 305],
  ["2022-04-25", 300],
  ["2020-02-27", 294],
  ["2020-04-05", 268],
  ["2021-12-26", 111],
  ["2019-07-06", 262],
  ["2021-08-31", 226],
  ["2022-01-31", 98],
  ["2022-07-31", 149],
  ["2019-12-25", 171],
  ["2019-05-25", 102],
  ["2019-05-31", 374],
  ["2020-06-20", 185],
  ["2021-01-06", 140],
  ["2021-04-09", 85],
  ["2021-10-16", 271],
  ["2020-06-26", 202],
  ["2022-01-04", 201],
  ["2021-07-03", 301],
  ["2022-04-07", 48],
  ["2019-12-27", 329],
  ["2020-09-21", 60],
  ["2021-06-11", 101],
  ["2020-02-02", 161],
  ["2019-07-07", 138],
  ["2019-04-13", 81],
  ["2020-10-24", 13],
  ["2019-08-11", 93],
  ["2021-05-22", 91],
  ["2020-02-09", 83],
  ["2022-05-24", 140],
  ["2020-08-10", 252],
  ["2020-01-18", 302],
  ["2021-02-09", 43],
  ["2019-07-21", 189],
  ["2022-01-24", 289],
  ["2021-10-30", 228],
  ["2019-05-30", 276],
  ["2019-05-05", 335],
  ["2020-11-24", 223],
  ["2021-02-13", 101],
  ["2022-05-04", 197],
  ["2020-08-20", 125],
  ["2020-05-15", 378],
  ["2019-08-12", 317],
  ["2021-01-01", 351],
  ["2019-11-19", 199],
  ["2021-10-01", 183],
  ["2020-06-24", 359],
  ["2019-04-28", 152],
  ["2020-05-03", 337],
  ["2021-08-17", 34],
  ["2021-01-17", 54],
  ["2020-12-12", 350],
  ["2021-09-09", 198],
  ["2021-12-21", 87],
  ["2019-04-11", 214],
  ["2022-06-12", 333],
  ["2019-06-23", 248],
  ["2019-09-07", 355],
  ["2020-09-03", 278],
  ["2020-09-07", 280],
  ["2020-11-28", 249],
  ["2020-08-03", 274],
  ["2021-11-19", 90],
  ["2022-05-31", 310],
  ["2021-11-16", 111],
  ["2019-08-29", 12],
  ["2020-10-18", 251],
  ["2021-06-21", 47],
  ["2020-03-02", 379],
  ["2019-12-03", 111],
  ["2022-01-25", 336],
  ["2020-07-24", 165],
  ["2020-07-20", 397],
  ["2020-10-22", 303],
  ["2022-05-11", 304],
  ["2020-05-16", 391],
  ["2021-09-05", 112],
  ["2021-09-17", 257],
  ["2021-03-02", 241],
  ["2022-06-05", 235],
  ["2021-06-19", 257],
  ["2020-01-13", 243],
  ["2021-12-27", 211],
  ["2019-07-24", 251],
  ["2020-09-04", 31],
  ["2021-04-14", 13],
  ["2020-09-10", 155],
  ["2019-10-16", 108],
  ["2022-08-02", 252],
  ["2021-07-04", 85],
  ["2019-11-24", 220],
  ["2019-06-21", 247],
  ["2020-05-25", 289],
  ["2022-08-01", 124],
  ["2022-04-11", 286],
  ["2019-05-28", 273],
  ["2020-01-12", 339],
  ["2021-09-26", 106],
  ["2019-06-11", 41],
  ["2020-05-05", 47],
  ["2021-12-04", 259],
  ["2020-02-17", 81],
  ["2020-06-25", 395],
  ["2021-04-17", 292],
  ["2020-10-21", 169],
  ["2021-10-21", 215],
  ["2019-05-11", 143],
  ["2021-07-27", 299],
  ["2019-10-18", 300],
  ["2022-07-19", 152],
  ["2020-12-07", 157],
  ["2019-07-01", 274],
  ["2019-07-28", 318],
  ["2020-06-10", 143],
  ["2022-08-08", 137],
  ["2020-11-15", 386],
  ["2021-10-15", 49],
  ["2020-02-03", 295],
  ["2020-08-07", 40],
  ["2019-10-19", 96],
  ["2021-07-18", 171],
  ["2020-01-16", 281],
  ["2022-01-08", 211],
  ["2022-08-05", 110],
  ["2021-07-06", 90],
  ["2022-01-01", 178],
  ["2020-02-25", 174],
  ["2019-04-12", 178],
  ["2022-05-15", 22],
  ["2020-06-07", 358],
  ["2020-12-21", 121],
  ["2020-01-09", 337],
  ["2020-06-03", 130],
  ["2021-04-15", 226],
  ["2021-08-23", 239],
  ["2022-05-16", 51],
  ["2021-05-20", 362],
  ["2022-01-18", 22],
  ["2021-09-22", 75],
  ["2019-12-11", 182],
  ["2020-09-05", 174],
  ["2020-03-20", 241],
  ["2021-07-02", 14],
  ["2022-01-26", 290],
  ["2020-10-04", 400],
  ["2019-10-20", 191],
  ["2022-06-11", 238],
  ["2021-04-06", 16],
  ["2021-07-30", 346],
  ["2021-02-01", 190],
  ["2020-05-14", 315],
  ["2021-09-02", 13],
  ["2019-05-16", 210],
  ["2019-05-04", 350],
  ["2020-01-26", 56],
  ["2020-04-13", 79],
  ["2022-03-20", 16],
  ["2021-02-12", 369],
  ["2020-05-28", 89],
  ["2019-06-12", 299],
  ["2022-01-21", 69],
  ["2020-10-03", 114],
  ["2022-07-29", 287],
  ["2020-03-04", 226],
  ["2020-07-10", 92],
  ["2021-06-23", 348],
  ["2021-03-13", 96],
  ["2019-08-23", 54],
  ["2020-02-08", 391],
  ["2022-04-15", 62],
  ["2020-06-06", 92],
  ["2019-04-20", 53],
  ["2021-02-23", 356],
  ["2020-04-22", 26],
  ["2019-10-04", 319],
  ["2019-11-26", 214],
  ["2020-05-18", 99],
  ["2021-09-25", 167],
  ["2020-11-13", 29],
  ["2020-10-12", 290],
  ["2020-07-11", 302],
  ["2022-06-30", 150],
  ["2019-05-23", 383],
  ["2021-12-01", 32],
  ["2019-07-31", 271],
  ["2021-05-09", 336],
  ["2019-06-22", 368],
  ["2019-06-18", 389],
  ["2022-05-07", 133],
  ["2019-06-03", 45],
  ["2019-12-01", 333],
  ["2020-02-07", 180],
  ["2021-05-12", 305],
  ["2022-03-28", 14],
  ["2022-04-28", 233],
  ["2022-05-25", 72],
  ["2019-11-11", 10],
  ["2019-06-02", 357],
  ["2022-07-20", 147],
  ["2019-05-03", 205],
  ["2020-12-10", 140],
  ["2019-11-01", 264],
  ["2020-02-16", 109],
  ["2020-06-12", 324],
  ["2022-05-05", 38],
  ["2022-06-25", 326],
  ["2020-06-29", 265],
  ["2020-02-23", 284],
  ["2020-11-08", 168],
  ["2022-06-06", 330],
  ["2021-01-07", 7],
  ["2020-09-29", 28],
  ["2021-04-20", 337],
  ["2021-06-28", 114],
  ["2021-04-24", 365],
  ["2020-01-04", 105],
  ["2021-10-02", 165],
  ["2020-04-04", 251],
  ["2020-02-21", 2],
  ["2020-01-20", 299],
  ["2022-02-01", 153],
  ["2021-10-27", 55],
  ["2021-03-25", 378],
  ["2019-12-09", 235],
  ["2019-08-17", 261],
  ["2019-09-12", 163],
  ["2022-02-17", 150],
  ["2020-02-11", 374],
  ["2021-10-05", 257],
  ["2021-12-08", 99],
  ["2020-07-12", 277],
  ["2019-09-10", 375],
  ["2022-02-23", 290],
  ["2021-06-02", 121],
  ["2020-10-13", 327],
  ["2019-06-30", 134],
  ["2021-10-09", 122],
  ["2021-03-20", 217],
  ["2021-11-29", 155],
  ["2020-11-19", 26],
  ["2019-05-09", 201],
  ["2019-11-15", 318],
  ["2022-04-01", 193],
  ["2020-03-10", 343],
  ["2021-07-24", 362],
  ["2021-07-22", 29],
  ["2021-03-19", 273],
  ["2020-10-19", 158],
  ["2022-07-30", 209],
  ["2022-07-18", 26],
  ["2022-06-21", 257],
  ["2019-12-06", 290],
  ["2020-07-04", 65],
  ["2022-02-12", 177],
  ["2019-09-02", 113],
  ["2020-12-09", 18],
  ["2020-02-13", 150],
  ["2022-04-24", 258],
  ["2019-05-10", 334],
  ["2019-11-14", 22],
  ["2019-11-25", 250],
  ["2020-08-06", 207],
  ["2020-11-07", 35],
  ["2019-11-10", 336],
  ["2020-07-25", 351],
  ["2019-09-17", 357],
  ["2020-11-26", 152],
  ["2022-01-27", 94],
  ["2020-12-17", 108],
  ["2021-09-16", 377],
  ["2019-09-18", 390],
  ["2020-08-13", 8],
  ["2019-08-14", 264],
  ["2020-06-22", 290],
  ["2022-02-13", 155],
  ["2020-08-11", 39],
  ["2019-04-03", 361],
  ["2019-08-27", 71],
  ["2019-08-16", 22],
  ["2022-08-07", 282],
  ["2022-07-23", 380],
  ["2019-04-08", 138],
  ["2019-05-06", 278],
  ["2021-11-30", 237],
  ["2022-04-13", 100],
  ["2022-07-08", 17],
  ["2020-12-06", 149],
  ["2019-09-28", 277],
  ["2021-11-08", 71],
  ["2020-11-29", 198],
  ["2020-05-07", 253],
  ["2022-06-17", 102],
  ["2019-09-20", 212],
  ["2020-03-24", 386],
  ["2019-07-27", 360],
  ["2021-08-13", 113],
  ["2019-10-22", 289],
  ["2022-01-11", 205],
  ["2020-07-27", 339],
  ["2021-08-05", 371],
  ["2021-01-08", 184],
  ["2022-03-18", 128],
  ["2021-04-01", 152],
  ["2020-10-15", 65],
  ["2020-09-28", 215],
  ["2022-03-15", 217],
  ["2021-05-18", 326],
  ["2022-04-09", 361],
  ["2020-12-05", 15],
  ["2020-06-18", 163],
  ["2019-07-29", 36],
  ["2022-07-21", 354],
  ["2019-10-02", 301],
  ["2021-11-07", 308],
  ["2020-06-05", 58],
  ["2021-04-28", 269],
  ["2021-10-31", 394],
  ["2021-01-25", 303],
  ["2021-04-11", 153],
  ["2022-05-30", 277],
  ["2021-01-09", 26],
  ["2020-02-18", 132],
  ["2022-05-29", 385],
  ["2021-05-24", 330],
  ["2020-04-10", 54],
  ["2022-05-01", 251],
  ["2022-05-22", 364],
  ["2019-12-10", 349],
  ["2021-03-24", 253],
  ["2020-05-29", 123],
  ["2019-09-24", 256],
  ["2019-04-19", 141],
  ["2019-07-13", 116],
  ["2022-03-31", 39],
  ["2021-11-28", 340],
  ["2021-03-10", 239],
  ["2021-11-09", 197],
  ["2021-12-07", 337],
  ["2022-05-28", 370],
  ["2021-09-29", 54],
  ["2021-03-17", 87],
  ["2020-07-01", 259],
  ["2022-01-30", 381],
  ["2020-03-27", 93],
  ["2021-10-18", 137],
  ["2019-09-29", 377],
  ["2020-09-25", 376],
  ["2021-03-29", 349],
  ["2021-04-04", 145],
  ["2021-04-21", 185],
  ["2021-01-26", 236],
  ["2022-04-12", 268],
  ["2022-05-23", 309],
  ["2019-07-03", 192],
  ["2021-03-08", 259],
  ["2021-04-23", 321],
  ["2019-08-20", 166],
  ["2021-09-08", 327],
  ["2020-10-05", 65],
  ["2022-07-28", 33],
  ["2019-06-16", 266],
  ["2021-06-15", 146],
  ["2022-02-15", 160],
  ["2020-10-01", 313],
  ["2021-11-11", 49],
  ["2020-07-05", 361],
  ["2019-12-22", 378],
  ["2019-12-12", 95],
  ["2021-07-21", 179],
  ["2019-05-07", 62],
  ["2022-02-19", 252],
  ["2019-05-29", 5],
  ["2021-08-11", 217],
  ["2020-04-23", 116],
  ["2020-09-17", 250],
  ["2021-12-17", 65],
  ["2022-01-19", 350],
  ["2020-04-29", 113],
  ["2019-11-27", 43],
  ["2020-09-19", 218],
  ["2021-03-09", 316],
  ["2020-03-25", 56],
  ["2020-09-30", 185],
  ["2020-09-02", 147],
  ["2019-07-25", 349],
  ["2022-04-08", 269],
  ["2020-08-30", 83],
  ["2020-11-27", 176],
  ["2021-07-14", 56],
  ["2020-12-29", 51],
  ["2020-04-06", 2],
  ["2021-07-28", 152],
  ["2020-03-13", 188],
  ["2020-01-11", 62],
  ["2021-06-05", 115],
  ["2020-02-10", 352],
  ["2019-06-27", 289],
  ["2020-07-22", 14],
  ["2021-02-17", 269],
  ["2021-09-06", 39],
  ["2022-05-03", 302],
  ["2020-04-17", 241],
  ["2022-03-17", 382],
  ["2020-03-06", 293],
  ["2022-04-02", 237],
  ["2021-06-25", 203],
  ["2020-07-06", 189],
  ["2020-11-20", 167],
  ["2019-06-25", 249],
  ["2022-03-10", 152],
  ["2019-10-13", 347],
  ["2020-07-19", 234],
  ["2021-12-14", 289],
  ["2021-05-29", 241],
  ["2022-01-29", 292],
  ["2020-06-17", 226],
  ["2019-07-08", 86],
  ["2020-05-19", 144],
  ["2022-04-04", 133],
  ["2022-06-14", 71],
  ["2022-02-22", 42],
  ["2019-10-27", 70],
  ["2020-12-27", 203],
  ["2021-04-10", 120],
  ["2019-04-27", 102],
  ["2021-07-16", 254],
  ["2020-01-30", 317],
  ["2022-03-22", 14],
  ["2022-07-14", 50],
  ["2021-12-13", 329],
  ["2020-07-30", 385],
  ["2021-06-10", 12],
  ["2021-01-14", 33],
  ["2020-03-12", 345],
  ["2021-01-27", 332],
  ["2019-05-14", 65],
  ["2020-06-23", 380],
  ["2020-08-22", 334],
  ["2019-08-15", 100],
  ["2021-04-12", 190],
  ["2022-01-07", 181],
  ["2021-06-08", 108],
  ["2019-07-11", 140],
  ["2020-08-12", 256],
  ["2022-04-19", 57],
  ["2021-07-12", 397],
  ["2022-07-13", 45],
  ["2022-06-01", 244],
  ["2020-11-16", 148],
  ["2021-11-15", 216],
  ["2021-03-18", 389],
  ["2020-04-20", 330],
  ["2021-01-18", 260],
  ["2020-12-31", 212],
  ["2020-12-28", 390],
  ["2021-11-05", 91],
  ["2022-03-08", 298],
  ["2020-07-02", 275],
  ["2021-02-16", 100],
  ["2021-11-21", 69],
  ["2020-10-11", 14],
  ["2020-11-03", 192],
  ["2021-05-08", 189],
  ["2019-10-07", 21],
  ["2019-09-01", 16],
  ["2020-09-24", 10],
  ["2019-10-14", 63],
  ["2022-06-02", 135],
  ["2022-08-06", 111],
  ["2022-07-01", 245],
  ["2019-08-02", 149],
  ["2021-11-23", 146],
  ["2021-02-14", 256],
  ["2021-07-08", 35],
  ["2022-03-16", 308],
  ["2021-03-07", 70],
  ["2021-05-13", 182],
  ["2021-04-29", 264],
  ["2022-01-23", 25],
  ["2022-03-11", 52],
  ["2020-03-09", 344],
  ["2019-04-25", 161],
  ["2021-02-25", 147],
  ["2022-02-09", 21],
  ["2020-09-16", 27],
  ["2022-04-20", 11],
  ["2021-12-28", 349],
  ["2022-03-13", 317],
  ["2019-05-26", 290],
  ["2019-07-23", 13],
  ["2019-04-06", 272],
  ["2022-03-26", 45],
  ["2020-09-13", 222],
  ["2021-09-28", 94],
  ["2021-11-10", 91],
  ["2019-11-28", 150],
  ["2021-01-13", 110],
  ["2020-06-02", 296],
  ["2021-07-13", 151],
  ["2019-10-03", 225],
  ["2019-06-09", 31],
  ["2020-10-30", 84],
  ["2019-07-22", 208],
  ["2019-04-23", 3],
  ["2021-11-06", 86],
  ["2020-10-09", 129],
  ["2022-06-24", 297],
  ["2019-06-10", 163],
  ["2021-07-07", 354],
  ["2019-12-05", 204],
  ["2022-01-13", 237],
  ["2021-05-19", 142],
  ["2021-08-03", 78],
  ["2020-03-29", 251],
  ["2022-07-07", 199],
  ["2022-08-11", 14],
  ["2021-02-28", 262],
  ["2020-01-24", 310],
  ["2020-09-18", 262],
  ["2021-05-27", 324],
  ["2021-10-24", 89],
  ["2021-05-11", 365],
  ["2021-07-17", 126],
  ["2021-12-19", 189],
  ["2021-01-10", 162],
  ["2021-08-15", 382],
  ["2019-08-31", 308],
  ["2020-10-27", 125],
  ["2022-06-16", 395],
  ["2019-05-08", 75],
  ["2020-05-27", 9],
  ["2022-06-03", 12],
  ["2021-09-27", 307],
  ["2019-08-18", 386],
  ["2020-12-01", 65],
  ["2020-04-02", 280],
  ["2022-01-12", 12],
  ["2022-01-14", 226],
  ["2020-09-20", 385],
  ["2020-07-15", 1],
  ["2020-06-14", 384],
  ["2019-11-21", 171],
  ["2021-05-28", 307],
  ["2021-05-05", 240],
  ["2020-02-01", 41],
  ["2021-06-12", 87],
  ["2022-01-06", 108],
  ["2020-12-18", 195],
  ["2020-03-31", 2],
  ["2021-10-23", 352],
  ["2022-05-21", 315],
  ["2019-08-04", 121],
  ["2021-10-11", 198],
  ["2019-11-13", 234],
  ["2021-12-02", 270],
  ["2021-01-23", 382],
  ["2019-10-09", 166],
  ["2021-11-24", 371],
  ["2020-07-09", 39],
  ["2021-05-01", 87],
  ["2022-05-27", 213],
  ["2022-05-18", 153],
  ["2021-08-08", 237],
  ["2020-06-19", 319],
  ["2021-03-21", 277],
  ["2020-01-23", 163],
  ["2022-01-16", 79],
  ["2022-04-23", 295],
  ["2022-04-14", 32],
  ["2020-08-05", 10],
  ["2019-12-16", 385],
  ["2022-06-18", 92],
  ["2021-02-20", 89],
  ["2021-05-07", 96],
  ["2020-08-02", 390],
  ["2019-08-01", 195],
  ["2021-01-28", 164],
  ["2020-04-30", 223],
  ["2020-07-29", 210],
  ["2022-02-08", 250],
  ["2020-02-22", 328],
  ["2020-08-23", 0],
  ["2020-10-07", 390],
  ["2022-05-19", 366],
  ["2019-06-29", 175],
  ["2020-12-22", 353],
  ["2019-05-02", 90],
  ["2020-10-26", 58],
  ["2020-10-17", 362],
  ["2020-04-07", 23],
  ["2021-04-27", 30],
  ["2019-10-29", 138],
  ["2020-08-24", 40],
  ["2020-02-05", 224],
  ["2021-03-28", 247],
  ["2021-05-30", 370],
  ["2020-03-03", 38],
  ["2021-01-12", 139],
  ["2020-01-21", 88],
  ["2020-08-08", 206],
  ["2020-03-26", 303],
  ["2020-11-02", 63],
  ["2019-12-29", 350],
  ["2020-01-01", 319],
  ["2020-06-28", 390],
  ["2019-10-31", 34],
  ["2020-04-12", 346],
];

const numberOfYears = (firstDay: string) => {
  return new Date().getFullYear() - new Date(firstDay).getFullYear() + 1;
};
