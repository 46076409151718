import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";

export default function JoinCreateGroup() {
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [modal, setModal] = useState(false);
  let navigate = useNavigate();
  let [groupName, setGroupName] = useState("");

  async function doClaim(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setModal(true);
    var { groupName } = document.forms[0];
    setGroupName(groupName.value);
  }

  async function accept(): Promise<any> {
    //console.log("accept");
  }

  function cancel(): any {
    //console.log("cancel");
    setModal(false);
    setLoading(false);
  }

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      {modal ? (
        <AcceptModal
          onAccept={accept}
          onCancel={cancel}
          Title="Join Group"
          Body={
            <div>
              Are you sure you, <b>{/*username*/}</b>, would like to join{" "}
              <b>{groupName}</b>?
            </div>
          }
          AcceptButton="Join"
          CancelButton="Cancel"
        />
      ) : null}

      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-xs md:max-w-sm m-2">
        <h1 className="text-center md:text-2xl text-lg font-semibold text-gray-600 mb-4">
          Join or Create a Group
        </h1>
        <form onSubmit={doClaim}>
          <div className="">
            <FormInput
              htmlFor="groupName"
              required={true}
              label="Group Name"
              type="text"
              value={searchParams.get("groupName") ?? undefined}
              tooltip="Auto fill this value by clicking the link in the email you received"
            />
            <FormInput
              htmlFor="groupOTP"
              label="One Time Password"
              type="text"
              value={searchParams.get("groupOTP") ?? undefined}
              tooltip="Auto fill this value by clicking the link in the email you received"
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            className="mt-4 w-full bg-gradient-to-tr from-blue-600 to-indigo-600 text-indigo-100 py-2 rounded-md text-lg tracking-wide flex items-center justify-center hover:from-blue-400 hover:to-indigo-400"
          >
            {loading ? <Spinner colour="fill-blue-600" /> : "Join Group"}
          </button>
        </form>
      </div>
    </div>
  );
}
