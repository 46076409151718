import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { doLogout } from "../../app/store";
import InputModal from "../../components/modals/InputModal";
import { English, LanguageText } from "../../dictionary/LoginText";
import { selectAuth } from "../../features/auth/authSlice";
import { clearUser, selectUser } from "../../features/user/userSlice";
import { useGetUserInfoQuery } from "../../services/userAPI";

function VerifyEmail() {
  let [loading, setLoading] = useState(false);
  let [Language, setLanguage] = useState(English);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const User = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  console.log(location);
  useEffect(() => {
    if (isVerifySuccess) {
      let from = (location.state as any) || {
        from: { pathname: "/home", search: "", state: "" },
      };
      const { pathname, search, state } = from;
      navigate(pathname + search, {
        replace: true,
        state,
      });
    }
    setLoading(false);
  }, [isVerifySuccess]);

  /*useGetUserInfoQuery(auth.user_id ?? "", {
    skip: User.id !== null && User.email !== null ? true : false,
  });*/

  function returnToLogin(): any {
    setLoading(false);
    doLogout();
  }
  async function verifyEmail(event: React.FormEvent<HTMLFormElement>): Promise<any> {
    event.preventDefault();
    setLoading(true);
    setIsVerifySuccess(true);
  }
  return (
    <VerifyEmailModal
      submitCode={verifyEmail}
      changeEmail={returnToLogin}
      Language={Language}
      email={User.email}
      loading={loading}
      value={searchParams.get("verificationcode")}
    />
  );
}

export default VerifyEmail;

type verifyEmailModalProps = {
  submitCode: (...args: any) => any;
  changeEmail: (...args: any) => any;
  Language: LanguageText;
  email: string | null;
  loading: boolean;
  value: string | null;
};

const VerifyEmailModal = ({
  submitCode,
  changeEmail,
  Language,
  email,
  loading,
  value,
}: verifyEmailModalProps) => {
  //console.log("verifyEmail");

  return (
    <InputModal
      onSubmit={submitCode}
      onCancel={changeEmail}
      Title="Verify Your Email"
      Loading={loading}
      Body={
        <>
          Please verify your email address by entering the code sent to <b>{email}</b> or
          by clicking the link in the email.
        </>
      }
      Form={[
        {
          htmlFor: "verifyEmail",
          label: "Email Verification Code",
          value: value,
          required: true,
          autoComplete: "one-time-code",
        },
      ]}
      AcceptButton={"Verify"}
      CancelButton={"Logout"}
    />
  );
};
