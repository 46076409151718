import React, { ForwardedRef, forwardRef, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdDashboard,
  MdSensors,
  MdGroups,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdFolderOpen,
  MdThermostat,
} from "react-icons/md";
import useWindowDimensions, { mdBreakPoint } from "../../utils/ScreenDimensions";
import { Device, selectDevices } from "../../features/devices/deviceSlice";
import { useAppSelector } from "../../app/hooks";
import AnimateHeight from "react-animate-height";

type Props = {
  sideBarIsOpen: boolean;
  setIsSideBarOpen: (isOpen: boolean) => void;
};

export const SideBar = forwardRef(
  ({ sideBarIsOpen, setIsSideBarOpen }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { devices } = useAppSelector(selectDevices);
    return (
      <div
        className={`h-full p-4 w-64 rounded bg-white shadow-md flex flex-col justify-between py-6 select-none overflow-y-scroll scrollbar-thin`}
        ref={ref}
      >
        <div>
          <ul>
            <li>
              <Dropdown title="Devices" icon={<MdSensors size={24} />} data={devices} />
              <Dropdown title="Dashboards" icon={<MdDashboard size={24} />} />
              <Dropdown title="Groups" icon={<MdGroups size={24} />} />
            </li>
          </ul>
        </div>
        {/*<div>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/home/device">All Devices</Link>
            </li>
            <li>
              <Link to="/home/device/claim">Claim</Link>
            </li>
            <li>
              <Link to="/home/device/DeviceID">Edit/View Device</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/home/group/join">Join Group</Link>
            </li>
            <li>
              <Link to="/home/group/GroupID">Edit/View Group</Link>
            </li>
            <li>
              <Link to="/home/dashboard/view">Edit/View Dashboard</Link>
            </li>
            <li>
              <button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh Page
              </button>
            </li>
          </ul>
        </div>*/}
      </div>
    );
  }
);

type DropdownProps = {
  title: string;
  icon: React.ReactNode;
  data?: Device[];
};

const Dropdown = ({ title, icon, data }: DropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      <button
        className="group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
        type="button"
        onClick={() =>
          navigate(title == "Dashboards" ? "/home/dashboard/view" : "/home/device")
        }
      >
        {icon}
        <span className="ml-3 flex-1 whitespace-nowrap text-left">{title}</span>
        {isOpen ? <MdKeyboardArrowDown size={24} /> : <MdKeyboardArrowRight size={24} />}
      </button>
      <AnimateHeight
        duration={500}
        height={isOpen ? "auto" : 0} // see props documentation below
        className="rounded-lg ml-4 "
      >
        {data?.map((device, index) => {
          return (
            <ul key={index}>
              <CollectionListing device={device} />
            </ul>
          );
        })}
      </AnimateHeight>
    </>
  );
};

type DeviceListProps = {
  device: Device;
};

const DeviceListing = ({ device }: DeviceListProps) => {
  return (
    <li className="">
      <MdThermostat size={24} />
      <span className="ml-3 flex-1 truncate relative" title={device.eui ?? ""}>
        {device.eui}
      </span>
    </li>
  );
};

const CollectionListing = ({ device }: DeviceListProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <button
        className={`group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MdFolderOpen size={24} />
        <span className="ml-3 flex-1 whitespace-nowrap text-left">{device.eui}</span>
        {isOpen ? <MdKeyboardArrowDown size={24} /> : <MdKeyboardArrowRight size={24} />}
      </button>
      <AnimateHeight
        duration={500}
        height={isOpen ? "auto" : 0} // see props documentation below
        className="rounded-lg ml-4 "
      >
        <ul className="space-y-2">
          <DeviceListing device={device} />
        </ul>
      </AnimateHeight>
    </>
  );
};
