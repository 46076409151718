import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../../features/user/userSlice";

type FormInputData = {
  optionValue: string;
  optionName: string;
};

export default function Claim() {
  const dispatch = useAppDispatch();
  let [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let [modal, setModal] = useState(false);
  let navigate = useNavigate();
  let [deviceID, setDeviceID] = useState("");
  let [groupID, setGroupID] = useState("");
  let { group } = useAppSelector(selectUser);

  let formInputData: FormInputData[] =
    group?.map((group: { partner_id: any; partner_name: any }) => {
      return {
        optionValue: group.partner_id,
        optionName: group.partner_name,
      };
    }) ?? [];

  async function doClaim(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setModal(true);
    var { deviceID } = document.forms[0];
    setDeviceID(deviceID.value);
  }

  async function accept(): Promise<any> {
    //console.log("accept");
  }

  function cancel(): any {
    //console.log("cancel");
    setModal(false);
    setLoading(false);
  }

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      {modal ? (
        <AcceptModal
          onAccept={accept}
          onCancel={cancel}
          Title="Claim Device"
          Body={
            <div>
              Are you sure you would like to add device <b>{deviceID}</b> to group{" "}
              <b>{groupID}</b>?
            </div>
          }
          AcceptButton="Confirm"
          CancelButton="Cancel"
        />
      ) : null}

      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
        <h1 className="text-center text-2xl font-semibold text-gray-600 mb-4">
          Claim a new device
        </h1>
        <form onSubmit={doClaim}>
          <div className="">
            <FormInput
              htmlFor="deviceID"
              required={true}
              label="Device ID"
              type="text"
              value={searchParams.get("deviceID") ?? undefined}
              tooltip="Auto fill this value by scanning the QR code on the device"
            />
            <FormInput
              htmlFor="groupID"
              label="Group"
              type="select"
              data={formInputData}
              value={groupID}
              onChange={(event) => setGroupID(event.target.value)}
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            className="mt-4 w-full bg-gradient-to-tr from-blue-600 to-indigo-600 text-indigo-100 py-2 rounded-md text-lg tracking-wide flex items-center justify-center hover:from-blue-400 hover:to-indigo-400"
          >
            {loading ? <Spinner colour="fill-blue-600" /> : "Claim Device"}
          </button>
        </form>
      </div>
    </div>
  );
}
