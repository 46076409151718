import React, { useEffect, useState } from "react";
import { FormInput } from "../../components/FormInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import InputModal from "../../components/modals/InputModal";
import Spinner from "../../components/Spinner";
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateUsername,
} from "../../utils/FormValidation";

import { English, French, LanguageText } from "../../dictionary/LoginText";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLoginUserMutation } from "../../services/authAPI";
import { login, selectAuth } from "../../features/auth/authSlice";

type FormErrors = {
  formError?: string | undefined;
  emailError?: string | undefined;
  usernameError?: string | undefined;
  passwordError?: string | undefined;
  confirmPasswordError?: string | undefined;
};

export default function Login() {
  let [type, setType] = useState("login");
  let [formErrors, setFormErrors] = useState<FormErrors>({});
  let [loading, setLoading] = useState(false);
  let [tsCsModal, setTsCsModal] = useState(false);
  let navigate = useNavigate();
  let [Language, setLanguage] = useState(English);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [
    loginUser,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginUserMutation();

  useEffect(() => {
    if (isLoginError) {
      setFormErrors({ formError: (loginError as any).data.message });
    }
    setLoading(false);
  }, [isLoginError]);

  useEffect(() => {
    if (isLoginSuccess) {
      let { from } = (location.state as any) || {
        from: { pathname: "/home", search: "", state: "" },
      };
      //console.log(from);
      const { pathname, search, state } = from;
      navigate(pathname + search, {
        replace: true,
        state,
      });
    }
    setLoading(false);
  }, [isLoginSuccess]);

  async function doLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    var { email, password, remember, confirmPassword, username, redirectTo, type } =
      document.forms[0];
    setLoading(true);
    if (type.value === "login") {
      if (validatePassword(password.value) /*|| validateEmail(email.value)*/) {
        setLoading(false);
        return setFormErrors({
          emailError: validateEmail(email.value),
          passwordError: validatePassword(password.value),
        });
      }
      await loginUser({
        body: {
          email: email.value,
          password: password.value,
        },
        rememberMe: remember.checked,
      });
    } else if (type.value === "register") {
      if (
        validatePassword(password.value) ||
        validateConfirmPassword(password.value, confirmPassword.value) ||
        validateEmail(email.value) ||
        validateUsername(username.value)
      ) {
        setLoading(false);
        return setFormErrors({
          emailError: validateEmail(email.value),
          passwordError: validatePassword(password.value),
          confirmPasswordError: validateConfirmPassword(
            password.value,
            confirmPassword.value
          ),
          usernameError: validateUsername(username.value),
        });
      }
      setTsCsModal(true);
    } else {
      setLoading(false);
      return setFormErrors({ formError: "Invalid form type" });
    }
  }
  async function acceptTsCs(): Promise<any> {
    setTsCsModal(false);
    setLoading(false);
    dispatch(
      login({
        token: "token",
        rememberMe: true,
        user_id: "userID",
      })
    );
    let { from } = (location.state as any) || {
      from: { pathname: "/home", search: "", state: "" },
    };
    //console.log("from ", from);
    navigate("/verifyemail", {
      replace: true,
      state: from,
    });
  }

  function closeTsCsModal(): any {
    setTsCsModal(false);
    setLoading(false);
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setFormErrors((form) => ({ ...form, [field]: "" }));
  };

  return (
    <div className="h-screen bg-gradient-to-br from-blue-600 to-indigo-600 flex justify-center flex-col items-center w-full">
      <>
        {tsCsModal ? (
          <TsCsConsentModal
            acceptTsCs={acceptTsCs}
            closeTsCsModal={closeTsCsModal}
            Language={Language}
          />
        ) : null}
      </>

      <img src="https://www.r-p-r.co.uk/newimages/rprfooter.png" />
      <h1 className="text-center text-6xl font-bold text-white mb-8">RPR-IoT</h1>
      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
        <h1 className="text-center text-4xl font-semibold text-gray-600">
          {type === "login" ? (
            <>{Language.buttons.login}</>
          ) : (
            <>{Language.buttons.register}</>
          )}
        </h1>
        <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
          {formErrors.formError}
        </div>
        <form onSubmit={doLogin}>
          <div className="">
            <FormInput
              htmlFor="email"
              label={Language.form.email}
              //type="email"
              value={undefined}
              autoComplete="email"
              required
              error={formErrors.emailError}
              onChange={(e) => handleInputChange(e, "emailError")}
            />
            {type === "login" ? null : (
              <FormInput
                htmlFor="username"
                label={Language.form.username}
                type="text"
                autoComplete="username"
                value={undefined}
                required
                error={formErrors.usernameError}
                onChange={(e) => handleInputChange(e, "usernameError")}
              />
            )}
            <FormInput
              htmlFor="password"
              label={Language.form.password}
              type="password"
              value={undefined}
              required
              autoComplete={type === "login" ? "current-password" : "new-password"}
              error={formErrors.passwordError}
              onChange={(e) => handleInputChange(e, "passwordError")}
            />
            {type === "login" ? null : (
              <FormInput
                htmlFor="confirmPassword"
                label={Language.form.confirmPassword}
                type="password"
                value={undefined}
                autoComplete="new-password"
                required
                error={formErrors.confirmPasswordError}
                onChange={(e) => handleInputChange(e, "confirmPasswordError")}
              />
            )}
            <div>
              <label htmlFor="remember" className="block mb-1 text-gray-600  ">
                <input
                  id="remember"
                  type="checkbox"
                  className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
                />
                {Language.form.remember}
              </label>
            </div>
          </div>
          <button
            disabled={loading}
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
          >
            {loading && type === "login" ? (
              <Spinner colour="fill-blue-600" />
            ) : type === "login" ? (
              <>{Language.buttons.login}</>
            ) : (
              <>{Language.buttons.register}</>
            )}
          </button>
          <input type="hidden" value={type} id="type" />
          <input
            type="hidden"
            defaultValue={searchParams.get("redirectTo") ?? undefined}
            id="redirectTo"
          />
        </form>
        <p
          onClick={() => {
            setType(type === "login" ? "register" : "login");
          }}
          className="block mb-1 text-gray-800 cursor-pointer"
        >
          {type === "login" ? (
            <>{Language.buttons.notGotAccount}</>
          ) : (
            <>{Language.buttons.GotAccount}</>
          )}
        </p>
      </div>
    </div>
  );
}

type TsCsConsentModalProps = {
  acceptTsCs: (...args: any) => any;
  closeTsCsModal: (...args: any) => any;
  Language: LanguageText;
};

const TsCsConsentModal = ({
  acceptTsCs,
  closeTsCsModal,
  Language,
}: TsCsConsentModalProps) => {
  return (
    <AcceptModal
      onAccept={acceptTsCs}
      onCancel={closeTsCsModal}
      Title={Language.termsAndConditions.title}
      Body={Language.termsAndConditions.body}
      AcceptButton={Language.termsAndConditions.accept}
      CancelButton={Language.termsAndConditions.cancel}
    />
  );
};
