import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { login } from "../features/auth/authSlice";
import { setUser } from "../features/user/userSlice";

type LoginResponse = {
  status: string;
  user_name: string;
  user_id: string;
  user_level: string;
  partner_id: string;
  token: string;
  message: string;
};

export const authAPI = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("wf-tkn", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (args: {
        body: { email: string; password: string };
        rememberMe: boolean;
      }) => {
        return {
          url: "/admin/login",
          method: "post",
          headers: {
            wf_pwd: args.body.password,
            wf_user_id: args.body.email,
          },
        };
      },
      transformResponse: (result: LoginResponse, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            login({
              token: data.token,
              rememberMe: args.rememberMe,
              user_id: data.user_id,
            })
          );
          dispatch(
            setUser({
              id: data.user_id,
              username: data.user_name,
              email: null,
              group: [],
              phoneNumber: null,
            })
          );
        } catch (error) {
          //console.log(error);
        }
      },
    }),
    registerUser: builder.mutation({
      query: (body: {
        username: string;
        email: string;
        password: string;
        rememberMe: boolean;
      }) => {
        return {
          url: "/users/signup",
          method: "post",
          body,
        };
      },
      transformResponse: (result: LoginResponse, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            login({
              token: data.token,
              rememberMe: args.rememberMe,
              user_id: data.user_id,
            })
          );
          dispatch(
            setUser({
              id: data.user_id,
              username: data.user_name,
              email: null,
              group: [],
              phoneNumber: null,
            })
          );
        } catch (error) {
          //console.log(error);
        }
      },
    }),
    verifyEmail: builder.mutation({
      query: (verificationCode: string) => {
        return {
          url: "/users/email/" + verificationCode,
          method: "post",
        };
      },
    }),
  }),
});

export const { useLoginUserMutation, useRegisterUserMutation, useVerifyEmailMutation } =
  authAPI;
