import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { addDevices } from "../features/devices/deviceSlice";

export type DeviceType = {
  eui: string;
  lat: number;
  lng: number;
  name: string;
  configuration: {
    [key: string]: Config[];
  };
  status: Status;
};

export type Config = {
  ident?: number;
  description: string;
  type: string;
  units: string;
  sensor_sn?: string;
};

type Status = {
  claimed: boolean;
  online: boolean;
  last_message_ts: Date;
};

export const deviceAPI = createApi({
  reducerPath: "deviceAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    /*prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("wf-tkn", `${token}`);
      }
      return headers;
    },*/
  }),
  tagTypes: ["device"],
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: () => {
        return {
          url: "/devices",
        };
      },
      transformResponse: (result: DeviceType[]) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addDevices(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetDevicesQuery } = deviceAPI;
