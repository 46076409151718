import React, { useEffect, useState } from "react";
import { MarkerMuster } from "react-leaflet-muster";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import {
  MapContainer,
  useMap,
  TileLayer,
  Popup,
  Marker,
  ImageOverlay,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { LatLngBoundsExpression, LatLngTuple } from "leaflet";
import Spinner from "../../../Spinner";
import "react-leaflet-markercluster/dist/styles.min.css";

const icon = L.Icon.Default as any;
delete icon.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function ImageMap() {
  useEffect(() => {
    //navigator.geolocation.getCurrentPosition((position) => {});
  });

  const [imageIsLoading, setImageIsLoading] = useState(true);
  const imageUrl =
    "https://images.adsttc.com/media/images/5c63/6f0f/284d/d1c5/8300/0002/slideshow/_ArchitectsWarehouse_IdleArch_GroundFloorPlan.jpg?1550020360";

  useEffect(() => {
    setImageIsLoading(true);
    loadImage(imageUrl);
  }, []);

  const loadImage = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setBounds([topLeft, [img.height, img.width]]);
      setImageIsLoading(false);
    };
    img.onerror = (err) => {
      //console.log("img error");
      console.error(err);
    };
  };

  const topLeft: LatLngTuple = [0, 0];
  const bottomRight: LatLngTuple = [0, 0];
  const [bounds, setBounds] = useState<LatLngBoundsExpression>([
    topLeft,
    bottomRight,
  ]);

  return (
    <>
      {!imageIsLoading ? (
        <MapContainer
          bounds={bounds}
          maxBounds={bounds}
          minZoom={-2}
          maxZoom={10}
          zoom={0}
          crs={L.CRS.Simple}
          style={{ height: "100%", width: "100%" }}
        >
          <ImageOverlay url={imageUrl} bounds={bounds} />
          <MarkerClusterGroup>
            <Marker position={[49.8397, 24.0297]} />
            <Marker position={[52.2297, 21.0122]} />
            <Marker position={[51.5074, -0.0901]} />
          </MarkerClusterGroup>
        </MapContainer>
      ) : (
        <div className="h-full flex justify-center items-center">
          <Spinner colour="fill-green-600" />
        </div>
      )}
    </>
  );
}

export default ImageMap;
