import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AuthState {
  token: string | null;
  rememberMe: boolean | null;
  user_id: string | null;
}

const initialState: AuthState = {
  token: null,
  rememberMe: null,
  user_id: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state: any,
      action: PayloadAction<{
        token: string;
        rememberMe: boolean;
        user_id: string;
      }>
    ) => {
      if (action.payload.rememberMe) {
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: action.payload.token,
            user_id: action.payload.user_id,
          })
        );
      } else {
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            token: action.payload.token,
            user_id: action.payload.user_id,
          })
        );
      }

      state.token = action.payload.token;
      state.user_id = action.payload.user_id;
    },
    //=============== Logout Action ===============
    clearAuth: (state: any) => {
      localStorage.setItem("auth", JSON.stringify({ undefined }));
      sessionStorage.setItem("auth", JSON.stringify({ undefined }));
      state.token = null;
      state.rememberMe = null;
      state.user_id = null;
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const { login, clearAuth } = authSlice.actions;

export default authSlice.reducer;

//I heada back to store.tsx that is found in the folder app
