import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

function Subscription() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-full flex items-center justify-center overflow-auto">
      <section
        className="

   bg-white
   relative
   z-20
   "
      >
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
                <span className="font-semibold text-lg text-primary mb-2 block">
                  Pricing Table
                </span>
                <h2
                  className="
                  font-bold
                  text-3xl
                  sm:text-4xl
                  md:text-[40px]
                  text-dark
                  mb-4
                  "
                >
                  Our Pricing Plan
                </h2>
                <p className="text-base text-body-color">
                  There are many variations of passages of Lorem Ipsum available but the
                  majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center -mx-4">
            <PlanCard
              title="Premium"
              cost={100}
              subtitle="The consumer version advised for customers with more than 15 devices to ensure they get the most from the platform"
              features={[
                "Be a member of upto 10 groups",
                "Unlimited Email Notifications",
                "Unlimited Devices",
                "Upto 6 Collections",
              ]}
              disabled={true}
            />
            <PlanCard
              title="Basic"
              cost="Free"
              subtitle="The Basic tier to test our platform"
              features={[
                "Be a member of upto 3 groups",
                "Upto Email 10 Notifications",
                "Unlimited Devices",
                "Upto 3 Collections",
              ]}
              onClick={() => {
                navigate("/home");
              }}
            />
            <PlanCard
              title="Enterprise"
              cost={250}
              subtitle="Get All of the features with the ability to request new features"
              features={[
                "Be a member of upto Unlimited groups",
                "Unlimited Email and SMS Notifications",
                "Unlimited Devices",
                "Unlimited Collections",
              ]}
              disabled={true}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscription;

type PlanCardType = {
  title: string;
  cost: number | string;
  subtitle: string;
  features: string[];
  disabled?: boolean;
  onClick?: () => void;
};

const PlanCard = ({
  title,
  cost,
  subtitle,
  features,
  disabled = false,
  onClick,
}: PlanCardType) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/3 px-4 h-full">
      <div
        className="
               bg-white
               rounded-xl
               relative
               z-10
               overflow-hidden
               border border-primary border-opacity-20
               shadow-pricing
               py-10
               px-8
               sm:p-12
               lg:py-10 lg:px-6
               xl:p-12
               h-full
               "
      >
        <span className="text-primary font-semibold text-lg block mb-4">{title}</span>
        <h2 className="font-bold text-dark mb-5 text-[42px]">
          {typeof cost === "number" ? (
            <>
              £{cost}
              <span className="text-base text-body-color font-medium">/ year</span>
            </>
          ) : (
            <>{cost}</>
          )}
        </h2>
        <p
          className="
                  text-base text-body-color
                  pb-8
                  mb-8
                  border-b border-[#F2F2F2]
                  "
        >
          {subtitle}
        </p>
        <div className="mb-7">
          {features.map((feature, index) => {
            return (
              <p key={index} className="text-base text-body-color leading-loose mb-1">
                {feature}
              </p>
            );
          })}
        </div>
        <button
          disabled={disabled}
          onClick={onClick}
          className={`
                  w-full
                  block
                  text-base
                  font-semibold
                  text-primary
                  border border-[#D4DEFF]
                  rounded-md
                  text-center
                  p-4
                  transition
                  ${
                    disabled
                      ? "bg-red-300 text-gray-500"
                      : "hover:bg-green-500 hover:text-white"
                  }
                  `}
        >
          Choose {title}
        </button>
      </div>
    </div>
  );
};
