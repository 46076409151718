import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { updateDeviceData } from "../features/locations/dataSlice";

export type UplinkData = {
  data_points: DataPoint;
  eui: string;
  time: string;
  date: string;
  ts: Date;
  port: number;
  gateways: Gateway[];
};

type Gateway = {
  name: string;
  rssi: number;
  snr: number;
};

export type DataPoint = {
  [key: string]: number[];
};

export const dataAPI = createApi({
  reducerPath: "dataAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    /*prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("wf-tkn", `${token}`);
      }
      return headers;
    },*/
  }),
  tagTypes: ["data"],
  endpoints: (builder) => ({
    getData: builder.mutation({
      query: (body: { eui: string; readings: number }) => {
        return {
          url: "/data",
          method: "post",
          body: {
            eui: body.eui,
            number: body.readings,
          },
        };
      },
      transformResponse: (result: UplinkData[]) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDeviceData(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetDataMutation } = dataAPI;
