import { useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { NavHeader } from "../components/nav/NavHeader";
import { SideBar } from "../components/nav/SideBar";
import { clearUser, selectUser } from "../features/user/userSlice";
import { useGetUserInfoQuery } from "../services/userAPI";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAuth } from "../features/auth/authSlice";
import useWindowDimensions, { mdBreakPoint } from "../utils/ScreenDimensions";
import { doLogout } from "../app/store";

function Home() {
  const { width } = useWindowDimensions();
  const [sideBarIsOpen, setIsSidebarOpen] = useState(width > mdBreakPoint);
  const User = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarIconRef = useRef<HTMLDivElement>(null);

  /*const { error, isError } = useGetUserInfoQuery(auth.user_id ?? "", {
    skip: User.email !== null ? true : false, //?true:false
  });

  useEffect(() => {
    if (isError) {
      //console.log(error);
      doLogout();
    }
  }, [isError]);*/

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [sideBarIsOpen]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sidebarRef?.current !== null &&
        sidebarIconRef?.current !== null &&
        width < mdBreakPoint
      ) {
        if (
          !sidebarRef.current.contains(event.target) &&
          !sidebarIconRef.current.contains(event.target)
        ) {
          setIsSidebarOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [sidebarRef, sidebarIconRef, width]);

  return (
    <div className="flex flex-col h-screen py-4 px-3 w-full bg-rpr-blue">
      <div className="max-h-14">
        <NavHeader
          setIsSideBarOpen={setIsSidebarOpen}
          sideBarIsOpen={sideBarIsOpen}
          ref={sidebarIconRef}
        />
      </div>
      <div className="flex flex-grow flex-shrink h-main-body">
        <div
          className={`basis-64 z-10 md:static absolute mr-6 top-0 bottom-0 md:mb-0 md:mt-4 mb-4 mt-22 ${
            sideBarIsOpen ? "" : "hidden"
          }`}
        >
          <SideBar
            setIsSideBarOpen={setIsSidebarOpen}
            sideBarIsOpen={sideBarIsOpen}
            ref={sidebarRef}
          />
        </div>
        <div className="mt-4 w-full overflow-y-auto overflow-x-hidden scrollbar-thin">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
