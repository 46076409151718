import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DeviceState {
  devices: Device[];
}

export type Device = {
  eui: string;
};

const initialState: DeviceState = {
  devices: [],
};

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    addDevices: (state: any, action: PayloadAction<Device[]>) => {
      state.devices = action.payload;
    },
    //=============== Logout Action ===============
    clearDevices: (state: any) => {
      state.devices = [];
    },
  },
});

export const selectDevices = (state: RootState) => state.devices;

export const { addDevices, clearDevices } = deviceSlice.actions;

export default deviceSlice.reducer;

//I heada back to store.tsx that is found in the folder app
