import { Link, Outlet } from "react-router-dom";

function LandingPage() {
  return (
    <div className="h-screen w-full flex">
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="bg-rpr-blue px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
          <h1 className="text-center text-2xl font-semibold text-white">
            rprIoT Dashboard Demo
          </h1>
          <div className="flex justify-center pt-4">
            <Link
              to="/home/dashboard/view"
              className="text-center text-xs font-semibold text-white "
            >
              <button className="bg-rpr-blue text-white font-bold text-2xl  py-2 px-4 rounded-full text-center shadow-2xl">
                Go to Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
