import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Claim from "./routes/device/Claim";
import Login from "./routes/authentication/Login";
import JoinCreateGroup from "./routes/groups/JoinCreateGroup";
import ViewEditGroup from "./routes/groups/ViewEditGroup";
import GroupIndex from "./routes/groups/GroupIndex";
import DeviceIndex from "./routes/device/DeviceIndex";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectAuth, login } from "./features/auth/authSlice";
import LandingPage from "./routes/LandingPage";
import Home from "./routes/Home";
import Subscription from "./routes/authentication/Subscription";
import SplashScreen from "./components/SplashScreen";
import ViewEditDevice from "./routes/device/ViewEditDevice";
import VerifyEmail from "./routes/authentication/VerifyEmail";
import { useEffect } from "react";
import { doLogout } from "./app/store";
import { selectUser } from "./features/user/userSlice";
import { useGetUserInfoQuery } from "./services/userAPI";
import Spinner from "./components/Spinner";
import DashboardIndex from "./routes/dashboard/DashboardIndex";
import ViewDashboard from "./routes/dashboard/ViewDashboard";
import DeviceTable from "./routes/device/DeviceTable";

export default function App() {
  const { token, user_id } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const User = useAppSelector(selectUser);

  if (!token) {
    if (localStorage.getItem("auth") !== null) {
      dispatch(login(JSON.parse(localStorage.getItem("auth") as string)));
    } else if (sessionStorage.getItem("auth") !== null) {
      dispatch(login(JSON.parse(sessionStorage.getItem("auth") as string)));
    }
  }

  /*const { error: getUserInfoError } = useGetUserInfoQuery(user_id ?? "", {
    skip: User.id === null && User.email === null && token ? false : true,
  });*/

  /*useEffect(() => {
    if (getUserInfoError) {
      doLogout();
    }
  }, [getUserInfoError]);*/

  const AuthLayout = () => {
    const location = useLocation();
    const isAuthenticated: boolean = true ? true : false;
    return isAuthenticated ? (
      <Outlet />
    ) : (
      <>
        <Navigate to="/login" replace state={{ from: location }} />
      </>
    );
  };

  const VerifiedEmail = () => {
    const location = useLocation();
    const isVerified: boolean = user_id === "U00000045" || user_id === "U00000068" ? true : true;
    return isVerified ? (
      <Outlet />
    ) : (
      <>
        <Navigate to="/verifyemail" replace state={{ from: location }} />
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          {/*Paths accessible to non-logged in users*/}
          <Route path="/" element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route element={<AuthLayout />}>
            {/*Paths only accessible to logged in users*/}
            <Route path="verifyemail" element={<VerifyEmail />} />
            <Route element={<VerifiedEmail />}>
              {/*Paths only accessible to users with a verified email*/}
              <Route path="subscription" element={<Subscription />} />
              <Route path="home" element={<Home />}>
                <Route index element={<Spinner colour="fill-red-500" />} />
                <Route path="device" element={<DeviceIndex />}>
                <Route index element={<DeviceTable />} />
                <Route path="claim" element={<Claim />} />
                <Route path=":deviceid" element={<ViewEditDevice />} />
              </Route>
              <Route path="group" element={<GroupIndex />}>
                <Route path="join" element={<JoinCreateGroup />} />
                <Route path=":groupid" element={<ViewEditGroup />} />
              </Route>
              <Route path="dashboard" element={<DashboardIndex />}>
                <Route path=/*":dashboardid"*/"view" element={<ViewDashboard />} />
              </Route>
              <Route path="user" element={<div>User</div>}>
                <Route path="settings" element={<div>settings</div>} />
                </Route>
                <Route path="help" element={<div>help</div>} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
